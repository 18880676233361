/* Bricks HTML Template */

/*** 

====================================================================
	Attachment Css Files
====================================================================

***/

@import url('global.css');
@import url('rtl.css');
@import url('header.css');
@import url('footer.css');
@import url('font-awesome.css');
@import url('flaticon.css');
@import url('animate.css');
@import url('owl.css');
@import url('preloader.css');
@import url('jquery-ui.css');
@import url('custom-animate.css');
@import url('jquery.fancybox.min.css');
@import url('jquery.mCustomScrollbar.min.css');
@import url('jquery.bootstrap-touchspin.css');
/*** 

====================================================================
	Main Slider Section
====================================================================

***/

.main-slider{
	position:relative;
	overflow:hidden;
}

.main-slider .slide{
	position:relative;
	overflow:hidden;
	padding:200px 0px 340px;
	background-size:cover;
	background-position:center right;
}

.main-slider .content-column{
	position:relative;
	max-width:760px;
}

.main-slider .content-column .inner-column{
	position:relative;
}

.main-slider .title{
	position:relative;
	font-weight: 500;
	opacity: 0;
	line-height:1.6em;
	transform: scaleY(0);
	transform-origin: top;
	color:var(--white-color);
	font-size:var(--font-18);
	text-transform:uppercase;
	margin-bottom: var(--margin-bottom-20);
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
	font-family: 'Raleway', sans-serif;
}

.main-slider .active .title{
	opacity: 1;
	transform: scaleY(1);
	-webkit-transition-delay: 300ms;
	-moz-transition-delay: 300ms;
	-ms-transition-delay: 300ms;
	-o-transition-delay: 300ms;
	transition-delay: 300ms;
}

.main-slider h1{
	color:var(--white-color);
	opacity: 0;
	transform: scaleY(0);
	transform-origin: top;
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
	margin-bottom:var(--margin-bottom-20);
}

.main-slider h1 span{
	color:#fff;
	font-family: 'Poppins', sans-serif;

}

.main-slider .active h1{
	opacity: 1;
	transform: scaleY(1);
	-webkit-transition-delay: 600ms;
	-moz-transition-delay: 600ms;
	-ms-transition-delay: 600ms;
	-o-transition-delay: 600ms;
	transition-delay: 600ms;
}

.main-slider .text{
	font-weight: 400;
	opacity: 0;
	line-height:1.8em;
	transform: scaleY(0);
	transform-origin: top;
	color:var(--white-color);
	font-size:var(--font-18);
	margin-bottom: var(--margin-bottom-35);
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 100ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1000ms ease;
}

.main-slider .active .text{
	opacity: 1;
	transform: scaleY(1);
	-webkit-transition-delay: 900ms;
	-moz-transition-delay: 900ms;
	-ms-transition-delay: 900ms;
	-o-transition-delay: 900ms;
	transition-delay: 900ms;
}

.main-slider .btns-box{
	opacity:0;
	transform: scaleY(0);
	transform-origin: top;
	display:inline-block;
	-webkit-transition: all 900ms ease;
	-moz-transition: all 900ms ease;
	-ms-transition: all 900ms ease;
	-o-transition: all 900ms ease;
	transition: all 900ms ease;
}

.main-slider .active .btns-box{
	opacity: 1;
	transform: scaleY(1);
	-webkit-transition-delay: 1200ms;
	-moz-transition-delay: 1200ms;
	-ms-transition-delay: 1200ms;
	-o-transition-delay: 1200ms;
	transition-delay: 1200ms;
}

.main-slider .play-box{
	position:absolute;
	left:50px;
	bottom:50px;
	z-index:10;
}

.main-slider .play-box .play-button span{
	position: relative;
	width:100px;
	height:100px;
	text-align:center;
	line-height:100px;
	display:inline-block;
	color:var(--main-color-two);
	font-size:var(--font-22);
	-webkit-transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	transition: all 600ms ease;
	background-color:var(--white-color);
}

.main-slider .play-box .play-button span:hover{
	color:var(--white-color);
	background-color:var(--main-color);
}

.main-slider .play-box .play-button .ripple,
.main-slider .play-box .play-button .ripple:before,
.main-slider .play-box .play-button .ripple:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width:100px;
	height:100px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
    -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
    -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
    -webkit-animation: ripple 3s infinite;
    -moz-animation: ripple 3s infinite;
    -ms-animation: ripple 3s infinite;
    -o-animation: ripple 3s infinite;
    animation: ripple 3s infinite;
}

.main-slider .play-box .play-button .ripple:before {
    -webkit-animation-delay: .9s;
    -moz-animation-delay: .9s;
    -ms-animation-delay: .9s;
    -o-animation-delay: .9s;
    animation-delay: .9s;
    content: "";
    position: absolute;
}

.main-slider .play-box .play-button .ripple:after {
    -webkit-animation-delay: .6s;
    -moz-animation-delay: .6s;
    -ms-animation-delay: .6s;
    -o-animation-delay: .6s;
    animation-delay: .6s;
    content: "";
    position: absolute;
}

@-webkit-keyframes ripple {
    70% {box-shadow: 0 0 0 70px rgba(253, 167, 0, 0);}
    100% {box-shadow: 0 0 0 0 rgba(253, 167, 0, 0);}
}

@keyframes ripple {
    70% {box-shadow: 0 0 0 70px rgba(253, 167, 0, 0);}
    100% {box-shadow: 0 0 0 0 rgba(253, 167, 0, 0);}
}

.main-slider .owl-dots{
	position:absolute;
	right:50px;
	top:50%;
	width:7px;
	z-index:10;
	min-height:200px;
	margin-top:-65px;
}

.main-slider .owl-dots .owl-dot{
	position:relative;
	width:3px;
	height:40px;
	margin:2px 0px;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	background-color:var(--white-color);
}

.main-slider .owl-dots .owl-dot.active,
.main-slider .owl-dots .owl-dot:hover{
	background-color:var(--main-color);
}

.main-slider .owl-nav{
	position:absolute;
	right:30px;
	top:50%;
	width:50px;
	z-index:10;
	min-height:260px;
	margin-top:-130px;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.main-slider .owl-nav .owl-prev{
	position:absolute;
	left:0px;
	top:0px;
	width:50px;
	height:50px;
	color:var(--main-color);
	font-size:var(--font-24);
	line-height:46px;
	text-align:center;
	border-radius:0px;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	background-color:var(--white-color);
}

.main-slider .owl-nav .owl-next{
	position:absolute;
	left:0px;
	bottom:0px;
	color:var(--main-color);
	font-size:var(--font-24);
	width:50px;
	height:50px;
	line-height:50px;
	border-radius:0px;
	text-align:center;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	background-color:var(--white-color);
}

.main-slider .owl-nav .owl-prev:hover,
.main-slider .owl-nav .owl-next:hover{
	background-color:var(--main-color);
	color:var(--white-color);
}
.icon.flaticon-menu{    color: #fff;}


/*my-css*/
.image-thumbnail img {
    border-radius: 25px;
    overflow: hidden;
    box-shadow: 0px 0px 18px 5px #00000040;
}
div#about-section {
    position: relative;
    z-index: 10;
    top: -131px;
}
.content-thumb h2 {
    color: #000;
    font-size: 40px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}
.content-thumb h4{
	font-family: 'Raleway', sans-serif;
	color: #000;
	font-size: 18px;
	margin-top: 10px;
	font-weight: 500;
}
.content-thumb p{
	font-family: 'Raleway', sans-serif;
	color: #000;
	font-size: 17px;
	margin-top: 10px;
	font-weight: 500;
}
a.btn.button-default {
    background-color: #de143a;
    padding: 12px 45px;
    border-radius: 50px;
    color: #fff;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    margin-left: 0px;
    margin-top: 15px;
	font-weight: 500;
}
.content-thumb {
    margin-top: 53px;
}
.right-content h3{
font-family: 'Raleway', sans-serif;
font-weight: 4000;
font-size: 17px;
	font-weight: 500;
}
.right-content h3 {
  font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 22px;
    color: #000;
    margin-top: 18px
}
.left-box {
    justify-content: start;
        display: flex;
    margin-top: 25px;
}
.left-icon {
    margin-right: 10px;
    width: 30%;
}

.thumbnail-box{
	margin-top: 10px;
}
#first-thumb{
	border-right: 1px solid #ccc;
}
#who_are_section{
	background-image: url(../images/common/bg1.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: right center;
	margin-top: 57px;
}
.section-box {
    background-color: #fff;
    width: 100%;
    max-width: 1200px;
    border-radius: 12px;
    margin: 50px auto 50px;
    padding: 25px;
    box-shadow: 0px 0px 11px 6px #cfcccc2b;
    position: relative;
    z-index: 10;
}
.section-box:before {
    position: absolute;
    content: "";
    background-image: url(../images/doted.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 204px;
    height: 141px;
    left: -64px;
    top: -38px;
    z-index: 1;
    opacity: 0.4;
}
.left_section h2{
	font-family: 'Poppins', sans-serif;
	font-size: 30px;
	color: #de143a;
	font-weight:700;
	margin-top: 25px;
}
.left_section p{
	font-family: 'Raleway', sans-serif;
    color: #000;
    font-size: 17px;
    margin-top: 10px;
    font-weight: 500;
	margin-bottom:0px;
}
.right_section img {
    width: 100%;
    overflow: hidden;
    border-radius: 13px;
}
.icon img {
    width: 100%;
    max-width: 54px;
    margin-left: 5px;
    border-radius: 0;
}
.right_box {
    display: flex;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 18px;
    position: absolute;
    right: -42px;
    bottom: -50px;
    padding: 17px;
}
.title h4 {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 32px;
    font-size: 32px;
    margin-right: 6px;
}

.separator {
    display: flex;
    align-items: center;
    width: 50%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.separator .line{
  height: 1px;
  flex: 1;
  background-color: #000;
}

.separator h2{
  padding: 10px;
font-family: 'Poppins', sans-serif;
font-weight: 600;
font-size: 30px;	
}
#services_section p{
	font-family: 'Raleway', sans-serif;
	font-size: 17px;
	color: #000;
	font-weight: 500;

}
.service-box-image img {
    border-radius: 19px;
    clip-path: polygon(0 1%, 100% 0, 100% 100%, 0 89%);
}
.service-thumbnail-box {
    border-radius: 10px;
    box-shadow: 0px 0px 11px 6px #b0aeae57;
    padding: 15px;
}
.service-icon{
	margin-top: -47px;
}
.service-icon img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
.service-content h2{
	 font-family: 'Poppins', sans-serif;
	 font-weight: 600;
	 font-size: 24px;
	 margin-top: 17px;
	 text-align: center;
	 color: #000;
	 text-transform: uppercase;
}

#services_section p {
   font-family: 'Raleway', sans-serif;
    font-size: 15px;
    color: #fff;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    margin-top: 4px;
        display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
div#service-button .btn-style-three {
    padding: 8px 32px;
    font-size: 14px;
}
div#service-button {
    display: flex;
    justify-content: center;
}

#help-section{
	background-image: url(../images/bg2.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	height: 389px;
	position: relative;
}
.image-box-section img {
    border-radius: 13px;
    overflow: hidden;
    margin: 25px;
    margin-top: 103px;
}
.content-box-section {
       background-color: #fff;
    padding: 25px 50px 0 50px;
    position: absolute;
    bottom: 132px;
}
.content-box-section h2{
	font-family: 'Poppins', sans-serif;
    font-size: 45px;
    font-weight: 600;
    margin-top: 25px;
}
.content-box-section p{
	font-family: 'Raleway', sans-serif;
    color: #000;
    font-size: 18px;
    margin-top: 10px;
    font-weight: 500;
}
.content-box-section strong{
	font-weight: 600;
	font-size: 18px;
	color: #000;
	font-family: 'Poppins', sans-serif;

    
}
#document-section{
	margin-top: 150px;
}
.image--thumb img {
    border-radius: 14px;
    overflow: hidden;
}
.content--thumb h2 {
   font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #000;
    font-size: 23px;
    margin-top: 22px;
}
.content--thumb p{
	font-family: 'Raleway', sans-serif;
    color: #000;
    font-size: 16px;
    margin-top: 10px;
    font-weight: 500;
    line-height: 24px;
}
a.btn.red-button {
    color: #de143a;
    padding-left: 0px;
    padding-top: 0px;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
}
svg.bi.bi-arrow-right {
    position: relative;
    top: 4px;
    fill: #000;
}
.btn.focus, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
}
.icon-icon {
    position: absolute;
    bottom: 8px;
    right: 8px;
}
.image--thumb {
    position: relative;
}
.left_video-content{
	background-color: #f3f3f3;

}
#video-section{
	background-color:#f3f3f3;
	margin-top: 50px;
}
.left_video-content p{
    font-family: 'Raleway', sans-serif;
    color: #000;
    font-size: 20px;
    margin-top: 10px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 1px;
}
.left_video-content h2{
	 font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 45px;
    margin-top: 22px;
}
a.btn.call-us-button {
    background-color: #de143a;
    margin-top: 25px;
    color: #fff;
    padding: 6px 38px;
    border-radius: 50px;
  font-family: 'Raleway', sans-serif;
	font-weight: 500;
}
a.btn.start-now-button{
 background-color: #021e7e;
    margin-top: 25px;
    color: #fff;
    padding: 6px 38px;
    border-radius: 50px;
   font-family: 'Raleway', sans-serif;
    margin-left: 8px;
	font-weight: 500;
}
.left_video-content {
    width: 362px;
    margin: 90px auto;
}
.right_video {
    position: relative;
    right: -31px;
}
button.close {
    position: relative;
    top: -9px;
}
.slider-box {
    background-color: #de143a;
    padding: 10px 9px;
    max-width: 299px;
    margin: -33px auto 35px;
    text-align: center;
    border-radius: 8px;
    position: relative;
    z-index: 10;
}
.slider-box h3{
	color: #fff;
	font-size: 17px;
	font-family: 'Raleway', sans-serif;
	font-weight: 500;
}

.carousel-control-prev-icon{
	background-image: url(../images/right-arrow.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	width: 50px;
	height: 50px;
}
.carousel-control-next-icon{
	background-image: url(../images/left-arrow.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	width: 50px;
	height: 50px;
}

#contact-section{
	background-color: #114489;
	padding-top: 35px;
	padding-bottom: 35px;
	clip-path: polygon(50% 0%, 100% 0, 100% 60%, 100% 95%, 48% 98%, 0 96%, 0 0);
}
.calling-icon {
    float: left;
    margin-right: 10px;
}
.calling-text h4 {
    color: #fff;
    margin-top: 8px;
   font-family: 'Raleway', sans-serif;
	font-weight: 500;
}
.contact-left-section h3{
	font-family: 'Poppins', sans-serif;
	color: #fff;
	font-size: 30px;
	margin-bottom: 18px;
	font-weight: 600;
	letter-spacing: 1px;
}
span.line {
    background-color: #fff;
    width: 1px;
    height: 44px;
    position: absolute;
    left: 43%;
}
.contact-left-section h2{
	font-family: 'Poppins', sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 35px;
    color: #fff;
    margin-bottom: 10px;
}
.contact-left-section p{
	color: #fff;
	font-family: 'Raleway', sans-serif;
	 font-size: 17px;
	font-weight:500;
}
.contact-right-section h2{
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	letter-spacing: 1px;
	font-size: 24px;
	color: #fff;
	margin-bottom: 20px;
}
input.form-control {
    height: 48px;
}
textarea#exampleFormControlTextarea1 {
    height: 160px;
    background-color: #fff;
}
button.btn.btn-primary {
    background-color: #de143a;
    padding: 11px 53px;
    border-radius: 50px;
}
.blog-thumbnail {
    border: 1px solid #0226a1;
    overflow: hidden;
    border-radius: 10px;
}
.blog-thumbnail img{
	width: 100%;
	transition: 1s;
}

.main-blog-box:hover img{
transform: scale(1.5);	
}
.blog-content h3 {
       font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-top: 4px;
    color: #f00;
}
.blog-content p {
    color: #000;
   font-family: 'Raleway', sans-serif;
    font-size: 17px;
    line-height: 24px;
    margin-top: 9px;
	font-weight: 500;
}
svg.bi.bi-calendar-check-fill {
    fill: #0226a1;
    margin-right: 8px;
}
.blog-content span{
	color: #000;
}
.blog-content span {
       color: #848181;
    font-weight: 500;
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
	font-weight: 500;
}
svg.bi.bi-calendar-check-fill {
    fill: #0226a1;
    margin-right: 8px;
    position: relative;
    top: 3px;
}
.blog-content {
    background-color: #fff;
    padding: 15px;
    margin: 15px;
    position: relative;
    top: -63px;
    border-radius: 10px;
    box-shadow: 0px 0px 12px 4px #b0acac2b;
}
.lda-right-section h2{
	color: #de143a;
	font-size: 30px;
	font-weight: 700;
	font-family: 'Poppins', sans-serif;
}
.lda-right-section p{
	font-family: 'Raleway', sans-serif;
	font-weight: 500;
	font-size: 17px;
	color: #000;
	margin-top: 15px;
}
div#lda-section {
    margin-top: 50px;
    padding-left: 0px;
    padding-right: 0px;
}
.lda-right-section {
    padding: 0px 50px 30px 0;
}
.lda-left-section {
    overflow: hidden;
    /* border-radius: 0px 20px 20px 0px; */
    border-radius: 20px;
}
#newsletter-section {
    background-image: url(../images/bg4.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 45px;
    padding-bottom: 45px;
}
.newsletter-content_part{
	display: flex;
    justify-content: center;
    align-items: baseline;
}
.newsletter-content_part h2{
	color: #fff;
	font-size: 25px;
	font-family: 'Raleway', sans-serif;
	font-weight: 500;

}
.newsletter-content_part h3{
	color: #fff;
	font-size: 30px;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	letter-spacing: 1;
}
.newsletter-content_part {
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding-top: 33px;
    padding-bottom: 33px;
}
.line2 {
    background-color: #fff;
    width: 89px;
    height: 1px;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
    top: -6px;
}
input.form-control.input-field {
    width: 50%;
    display: inline-block;
}
.newsletter_section {
    margin: 0px auto;
    text-align: center;
}
.newsletter_section {
    margin: 0px auto;
    text-align: center;
    position: relative;
    left: 27%;
}
button.btn.btn-denger {
    padding: 11px;
    margin-left: -4px;
    margin-top: -3px;
    border-radius: 0px 11px 11px 0px;
    background-color: #0226a1;
    color: #fff;
}
input.form-control.input-field {
    width: 64%;
    display: inline-block;
    border-radius: 11px 0px 0px 11px;
}
.slider-part {
    margin-top: 35px;
}
.slider-part h3{
	font-family: 'Raleway', sans-serif;
	font-size: 18px;
	color: #000;
	font-weight: 500;
}
.slider-part h2{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: 33px;
    color: #000;
    margin-top: 17px;	
}
.user img {
    max-width: 83px;
}
#testimonial p{
	font-family: 'Raleway', sans-serif;
	font-size: 15px;
	color: #000;
	font-weight: 500;	
}
#testimonial .owl-prev,#testimonial .owl-next{
    float: left;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background: #DE143A;
    color: #fff;
    font-size: 30px;
    margin-right: 10px;
    margin-bottom: 10px;
}
#videosection .owl-prev,#videosection .owl-next{
    float: left;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background: #DE143A;
    color: #fff;
    font-size: 30px;
    margin-right: 10px;
    margin-bottom: 10px;
}
.client-image img{
	position: relative;
    bottom: -20px;
}
.user-name{
	font-family: 'Poppins', sans-serif;
	font-size: 15px;
	font-weight: 600;
	letter-spacing: 1px;
	color: #000;
}
div#testimonails-section {
    position: relative;
}
img.img-fluid.quote {
    position: absolute;
    right: 0px;
    top: 36%;
    transform: translateY(-50%);
}

.btns-box a{
	border-radius: 30px;
}

/*30-05*/
#about_section{
	margin-top:50px;
}
.about_right_content h2{
	 font-family: 'Poppins', sans-serif;
    font-weight: 600;
	font-size:45px;
}
.about_right_content h2 strong{
	font-weight:800;
	letter-spacing:1px;
	color:#000;
}
.about_right_content p{
	font-family: 'Raleway', sans-serif;
    color: #000;
    font-size: 17px;
	margin-top:10px;
	font-weight: 500;
}
/*about-css*/
.about-content {
    display: flex;
    height: 389px;
    justify-content: center;
    align-items: center;
}
.breadcrumbs h1{
	color: #fff;
    font-size: 45px;
    font-weight: 600;
   font-family: 'Poppins', sans-serif;
    letter-spacing: 2px;
	text-shadow: 1px 1px 0px BLACK;
}
.breadcrumbs ul {
    list-style: none;
	text-align:center;
}
.breadcrumbs {
    text-align: center;
}
.breadcrumbs li {
    display:inline-block;
	color:#fff;
	font-family: 'Raleway', sans-serif;
	margin-right:1px;
	font-weight: 500;
    font-size: 18px;
}
.breadcrumbs li:nth-child(2){margin:0 10px;}
.breadcrumbs li a{
	color:#fff;
	font-family: 'Raleway', sans-serif;
	font-weight: 500;
}
.spacing {
    margin-top: 146px;
}
#inner-banner{
	background-image:url(../images/home/inner-banner.jpg);background-size:cover;background-repeat:no-repeat;background-position: center center;
    height: 389px;
    background-attachment: fixed;
}
div#contact_section {
    margin-top: 51px;
}
.left-contact-box {
    background-color: #04268a;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 6px 4px #a4a3a36b;
}
.left-contact-box h2 {
    color: #fff;
    font-size: 31px;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    font-weight: 500;
    margin-bottom: 25px;
}
.contact-box {
    margin-top: 35px;
    text-align: center;
    background-color: #f6f6f6;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 7px 3px #bab7b766;
    border: 1px solid red;
	margin-bottom:25px;
	height:264px;
}
.content-box h2 {
    color: #000;
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    letter-spacing: 1px;
    margin-top: 16px;
}
.content-box p{
font-family: 'Raleway', sans-serif;
	font-size:17px;
	margin-top:15px;
	font-weight:500;
	color: #000;
}
.content-box a {
    font-weight: 600;
    color: #000;
    font-size: 17px;
}
.area-left-part h2 {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 18px;
	font-family: 'Poppins', sans-serif;

}
.area-left-part h4 {
    color: #000;
    font-size: 17px;
    margin-bottom: 10px;
	font-family: 'Raleway', sans-serif;
	font-weight:500;
}
.area-left-part p {
    color: #000;
    font-size: 18px;
	font-family: 'Raleway', sans-serif;
	font-weight: 500;
}
.area-right-part img {
    border-radius: 10px;
    box-shadow: 0px 0px 7px 3px #b7b5b5a1;
}
div#offer-section{
	margin-top:50px;
}
div#offer-section h2 {
    text-align: center;
	font-family: 'Poppins', sans-serif;
	font-weight:600;
	font-size:35px;
}
.service-box {
    background-color: #fff;
    border: 1px solid red;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 9px;
    box-shadow: 0px 0px 7px 4px #b3b3b396;
	height:100%;
}
.service-title h2 {
    color: #000;
    font-size: 29px !important;
    margin-top: 20px;
    margin-bottom: 20px;
	font-family: 'Poppins', sans-serif;
	font-weight:600;
	letter-spacing:1px;
}

.comment-box i{
	font-size:22px;
	font-weight:600;
	color:#fff;
}
.comment-box {
    background-color: #021e7e;
    padding: 15px;
    border-radius: 15px;
    text-align: center;
    margin-top: 35px;
    margin-bottom: 30px;
}
#serving-section h2{
font-family: 'Poppins', sans-serif;
	font-weight:600;
	color:#fff;
	font-size:45px;
	letter-spacing:1px;
}
#serving-section p{
color: #fff;
    font-size: 17px;
	font-family: 'Raleway', sans-serif;
	font-weight: 500;
}
div#serving-section {
    text-align: center;
    padding-top: 40px;
}
.table {
    width: 100%;
    max-width: 100%;
    border: 1px solid #f00;
    margin-top: 25px;
    margin-bottom: 25px;
    background-color: #fff0;
}
table.table td {
    color: #fff;
    font-weight: 500;
    font-size: 17px;
    border: 1px solid #d2c9c9;
	font-family: 'Raleway', sans-serif;
}
.left-part-area h2{
font-family: 'Poppins', sans-serif;
	font-weight:600;
	font-size:40px;
	letter-spacing:1px;	
}
.left-part-area p{
font-family: 'Raleway', sans-serif;
	font-size:17px;
	color:#000;
	margin-top:18px;
	font-weight: 500;
}
.right-part-area img {
    border-radius: 15px;
    box-shadow: 0px 0px 6px 4px #b7b2b278;
}
div#legal-service-section {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
}
div#legal-service-section h2 {
	color:#fff;
	font-family: 'Poppins', sans-serif;
	font-weight:600;
	font-size:40px;
	letter-spacing:1px;	
}
div#legal-service-section p{
font-family: 'Raleway', sans-serif;
	font-size:18px;
	color:#fff;
	margin-top:18px;
	font-weight: 500;
}
.right-box-part h2{
font-family: 'Poppins', sans-serif;
	font-weight:500;
	font-size:45px;
	letter-spacing:1px;
	color:#000;
}
.right-box-part p{
font-family: 'Raleway', sans-serif;
	font-size:18px;
	color:#000;
	font-weight: 500;
}
.left-box-part {
    float: left;
    width: 20%;
}
.box-1x {
    background-color: #fff;
    border: 1px solid #e4dbdb;
    clear: both;
    height: auto;
    border-radius: 19px;
    overflow: hidden;
    margin-top: 35px;
    box-shadow: 0px 0px 8px 9px #ada9a945;
}
.right-box-part {
    margin-top: 25px;
}
.card.card-body {
    border: none;
    padding: 0px;
}
a.btn.show-more-button {
    color: #F3012A;
    padding-left: 0px;
	font-family: 'Raleway', sans-serif;
	font-weight: 500;
}
.eviction-content {
    padding: 21px;
}
.eviction-content h2 {
    font-size: 30px;
    margin-bottom: 6px;
	font-family: 'Poppins', sans-serif;
}
.service-box p{

    font-family: 'Raleway', sans-serif;
    font-size: 17px;
    color: #000;
    font-weight: 500;
	
}
.eviction-content p{
font-family: 'Raleway', sans-serif;
font-size:18px;
color:#000;	
	font-weight: 500;
}
.eviction-thumbnail {
    border: 1px solid #f00;
    overflow: hidden;
    border-radius: 5px;
    margin: 6px;
    box-shadow: 0px 0px 6px 4px #cbc6c645;
	height:100%;
}

.eviction-content li{
	color:#000;
	font-family: 'Raleway', sans-serif;
	font-weight:500;
	font-size:15px;
	margin-top:16px;
}
button.owl-prev span {
    font-size: 0px !important;
}
button.owl-next span{
font:0px !important;	
}
.bold-title h2 {
    font-size: 43px;
}
.bold-title h2 span{
	color:#f3012a;
}
.bold-title h4 {
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 15px;
    font-size: 28px;
    box-shadow: 0px 0px 7px 4px #c3bfbf4f;
}
.card.card-body h3 {
    font-size: 24px;
    color: #000;
}
div#provisions-part {
    background-color: #f3012a;
    padding-top: 25px;
    padding-bottom: 25px;
}
.provisions-section h2 {
    color: #fff;
	font-family: 'Poppins', sans-serif;
	font-weight:600;
	letter-spacing:1px;
	font-size:40px;
}
.provisions-section p {
    color: #fff;
	font-family: 'Raleway', sans-serif;
	font-size:18px;
	margin-top:10px;
	line-height: 27px;
	font-weight: 500;
}
.provisions-section {
    max-width: 586px;
    margin: 35px 0 0 50px;
}
div#collapseExample2 p {
    color: #000;
}
.provisions-image {
    max-width: 481px;
    border-radius: 15px;
    overflow: hidden;
    margin-top: 35px;
    box-shadow: 0px 0px 7px 4px #25232336;
}
#five-section{
	padding-top:35px;
	padding-bottom:35px;
}
#five-section h2{
color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 33px;
    text-align: center;
}
#five-section h4{
	text-align:center;
}
#five-section p{
font-family: 'Raleway', sans-serif;
font-size:18px;
	color:#000;
	margin-top:25px;
	text-align:center;
	font-weight: 500;
}
#section-six{
text-align:center;
}
#section-six h2{
color: #192c71;
	font-family: 'Poppins', sans-serif;
	font-weight:600;
	letter-spacing:1px;
	font-size:45px;	
	margin-bottom:25px;
}
#section-six h3{
	color: #F3012A;
	font-family: 'Poppins', sans-serif;
	font-weight:600;
	font-size:21px;		
}
#section-six p{
font-family: 'Raleway', sans-serif;
font-size:18px;
	color:#000;
	margin-top:25px;
	text-align:center;	
	font-weight: 500;
}
.thumbnail-section-six h3 {
    font-size: 25px !important;
	font-family: 'Poppins', sans-serif;
	line-height:22px;
}
.thumbnail-section-six span{
	display:block;
	font-family: 'Raleway', sans-serif;
font-size:16px;
	color:#000;
	margin-top:3px;
	text-align:center;
	font-weight: 500;
}
.thumbnail-section-six {
    background-color: #fff5f5;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    border: 1px solid red;
	height:100%;
}
.price-section-left {
    margin-top: 50px;
    text-align: center;
    border: 1px solid #f3012a;
    margin-bottom: 25px;
    padding-bottom: 25px;
	height:100%;
	box-shadow: 0px 0px 6px 5px #9893936b;
	border-radius: 13px;
    overflow: hidden;
}
.price-section-left h2 {
    background-color: #0A1F67;
    padding-top: 15px;
    padding-bottom: 15px;
	color:#fff;
	font-size:20px;
}
.price {
    font-size: 26px;
    font-weight: 600;
    margin-top: 44px;
    margin-bottom: 32px;
	font-family: 'Poppins', sans-serif;
	color:#f3012a;
}
.price-section-left h4 {
    color: #000;
    font-size: 20px;
    margin-bottom: 15px;
	margin-top:15px;
}
.price-section-left ul {
    text-align: left;
    margin-left: 118px;
}
.price-section-left li{
	color:#000;
	font-size:16px;
	margin-top:15px;
	font-family: 'Raleway', sans-serif;
	font-weight: 500;
}
.price-section-left p {
    padding-left: 28px;
    padding-right: 28px;
	font-family: 'Raleway', sans-serif;
	color:#000;
	font-size:18px;
	font-weight: 500;
}
a.btn.appointment-button {
    color: #000;
    margin-left: 36px;
    border: 1px solid red;
    border-radius: 50px;
    padding: 15px;
    box-shadow: 0px 0px 6px 4px #e158585c;
}
div#eviction-section h3 {
    text-align: center;
    font-size: 70px;
    margin-bottom: 25px;
    margin-top: 25px;
	font-family: 'Poppins', sans-serif;
	font-weight:600;
	letter-spacing:1px;
	-webkit-text-stroke-width: 2px;
    stroke-width: 2px;
    -webkit-text-stroke-color: #F3012A;
    stroke: #F3012A;
	color:#fff;
}
.left_section li{
	font-size:15px;
	color:#000;
	font-family: 'Raleway', sans-serif;
	margin-top:12px;
	font-weight:500;
}
div#document-section\ claims-section {
    margin-top: -64px;
}
.bg-white{
	background-color:white;
}
.lda-right-section h3 {
    font-size: 18px;
    color: #000;
}
.ul-style-2 li {
    margin: 5px 0 5px 0;
}
.ul-style-2 li:before{
    font-family: "FontAwesome";
    content: "\f00c";
    margin-right: 15px;
    background: #F3012A;
    padding: 5px;
    border-radius: 3px;
    font-size: 12px;
}
.ul-style-2 li:before{
	color:#fff;
}
ul.ul-style-2 li a {
    color: #000;
    font-weight: 500;
    font-size: 17px;
	font-family: 'Raleway', sans-serif;
	font-weight: 500;
}
ul.ul-style-2 li{
	margin-top:12px;
}
ul.ul-style-2 {
    background-color: #f6f4f4;
    padding: 15px;
    height: 100%;
    margin-top: 5px;
    border: 1px solid #f00;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 3px #0000002b;
}
div#area-section h2{
margin-top: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: left;
}
section.arae-middle-section {
    background-image:url(../images/common/Handshake-BG.jpg);
	background-size:cover;
	background-repeat:no-repeat;
	background-position:center;
    padding-top: 1px;
    margin-top: 35px;
    padding-bottom: 40px;
}
.living-thumbnail h2{
font-family: 'Poppins', sans-serif;
	font-weight:600;
	font-size:30px;
}
.living-thumbnail p{
font-size:15px;
	color:#000;
	font-family: 'Raleway', sans-serif;
	font-weight: 500;
		
}
.living-thumbnail {
    background-color: #fff;
    box-shadow: 0px 0px 5px 5px #c0bbbb4f;
    padding: 17px;
    margin-top: 25px;
    border-radius: 13px;
}
.living_trust h2 {
    color: #fff;
}
.living_trust p{
	color:#fff;
	font-family: 'Raleway', sans-serif;
	margin-top:25px;
	font-weight: 500;
}
.product_thumb {
    border: 1px solid #f00;
    background-color: #fff;
    box-shadow: 0px 0px 6px 6px #a8a8a842;
    padding: 18px;
    text-align: center;
    border-radius: 8px;
	height:100%;
}
.number-box {
    position: relative;
    top: -39px;
}
.product_thumb h2{
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 30px;
}
.product_thumb p{
font-family: 'Raleway', sans-serif;
	font-size:18px;
	margin-top:14px;
	font-weight: 500;
}
div#attorney-section h3{
font-family: 'Raleway', sans-serif;
	font-weight:500;
	color:#000;
	font-size:24px;
	text-align:center;
}
.attorney-icon-section {
    text-align: center;
    border: 1px solid #f00;
    border-radius: 6px;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 6px 5px #b7b2b247;
    padding-top: 20px;
    padding-bottom: 20px;
}
.icon-box img {
    max-width: 101px;
    margin-bottom: 18px;
}
div#attorney-section h2{
font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #000;
    font-size: 35px;
    text-align: center;
    margin-top: 50px;
	
}
#process-section h2{
font-family: 'Poppins', sans-serif;
	font-weight:600;
	color:#000;
	font-size:40px;
	text-align:center;	
}
#process-section p{
font-family: 'Raleway', sans-serif;
	font-size:18px;
	margin-top:14px;
	color:#000;
	font-weight:500;
}
.attorney-service h2 {
    font-size: 22px;
    padding: 15px;
}
div#additional-section {
    background-color: #D0002D;
    margin-top: 50px;
    margin-bottom: 25px;
	padding-top:35px;
	padding-bottom:35px;
}
div#additional-section h2{
font-family: 'Poppins', sans-serif;
	font-weight:600;
	color:#fff;
	font-size:40px;
	text-align:center;		
}
.additional-box {
    background-color: #fff;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0px 0px 9px 4px #d4000012;
	text-align:center;
	height:100%;
}
.additional-box h3 {
    font-size: 19px;
    margin-bottom: 10px;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    font-weight: 600;
    color: #000;
}
#service-sections p{
font-family: 'Raleway', sans-serif;
	font-size:18px;
	margin-top:4px;
	color:#000;	
		font-weight:500;
}
.thumbnail_box {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 6px 5px #00000021;
    height: 100%;
    padding: 12px;
	text-align:center;
}
.thumbnail_box_content h2 {
    font-size: 22px;
    margin-top: 14px;
}
.calling-icon img{
	max-width:52px;
}
.contact-form {
    margin-top: 35px;
}
.contact-left-section img {
    max-width: 100%;
}
.contact-location {
    margin-top: 122px;
    background-color: #fff;
    border: 1px solid #f00;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0px 0px 6px 5px #00000024;
}

.contact-location li {
    color: #000;
    font-size: 17px;
    line-height: 33px;
	font-family: 'Raleway', sans-serif;
		font-weight:500;
}
.contact-location a {
    color: #000;
    font-size: 17px;
    line-height: 33px;
	font-family: 'Raleway', sans-serif;
		font-weight:500;
}
.contact-location h2{
	color:#000;
	margin-bottom:0px;
}
.red-line {
    background-color: red;
    height: 2px;
    width: 50px;
}
.contact-location ul li svg {
    fill: #f00;
    margin-right: 6px;
}
div#services_section p {
    color: #000;
	font-family: 'Raleway', sans-serif;
	font-size:17px;
		font-weight:500;
}
.lda-right-section li{
	font-family: 'Raleway', sans-serif;
	color:#000;
	font-size:17px;
	line-height:30px;
}
article#post-800  h2 {
    font-size: 23px;
    margin-top: 25px;
	font-family: 'Poppins', sans-serif;
	letter-spacing:1px;
	font-weight:600;
}
	article#post-800 h3 {
    color: #000;
    font-size: 17px;
    margin-top: 9px;
    margin-bottom: 15px;
		letter-spacing:1px;
	font-weight:500;
	font-family: 'Raleway', sans-serif;
}
	article#post-800 strong {
    font-weight: 800;
    color: #000;
    font-size: 15px;
}
	article#post-800 li {
    line-height: 30px;
    color: #000;
	font-family: 'Raleway', sans-serif;
			font-weight:500;
}
#post-843 h2{
 font-size: 23px;
    margin-top: 25px;
	font-family: 'Poppins', sans-serif;
	letter-spacing:1px;
	font-weight:600;	
}
#post-843 h3 {
    color: #000;
    font-size: 20px;
    margin-top: 9px;
    margin-bottom: 15px;
		letter-spacing:1px;
	font-weight:40;
		font-family: 'Raleway', sans-serif;
		font-weight:500;
}
#post-843 strong {
    font-weight: 800;
    color: #000;
    font-size: 15px;
}
	#post-843 li {
    line-height: 30px;
    color: #000;
		font-family: 'Raleway', sans-serif;
			font-weight:500;
}
.left-document ul {
    border: 1px solid #f00;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 3px #b2b2b236;
    margin-bottom: 25px;
}
.left-document li a {
    line-height: 36px;
    font-weight: 500;
    color: #000;
    font-size: 16px;
	font-family: 'Raleway', sans-serif;
}

.right-document ul {
    border: 1px solid #f00;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px 3px #b2b2b236;
    margin-bottom: 25px;
}
.right-document li a {
    line-height: 36px;
    font-weight: 500;
    color: #000;
     font-size: 16px;
	font-family: 'Raleway', sans-serif;
}
.left-document li a svg {
    border: 1px solid #f00;
    fill: #fff;
    background-color: #f00;
    width: 25px;
    height: 25px;
    padding: 3px;
    border-radius: 8px;
    position: relative;
    top: 7px;
    margin-right: 10px;
}
.right-document li a svg {
    border: 1px solid #f00;
    fill: #fff;
    background-color: #f00;
    width: 25px;
    height: 25px;
    padding: 3px;
    border-radius: 8px;
    position: relative;
    top: 7px;
    margin-right: 10px;
}
.left-site-map {
    border: 1px solid #f00;
    padding: 15px;
    border-radius: 12px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.left-site-map li a {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
	font-family: 'Raleway', sans-serif;
}
.right-site-map{
 border: 1px solid #f00;
    padding: 15px;
    border-radius: 12px;
    margin-top: 20px;
    margin-bottom: 20px;	
}
.right-site-map li a {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
	font-family: 'Raleway', sans-serif;
}
.left-site-map li a svg {
    fill: #f00;
    margin-right: 8px;
    position: relative;
    top: 3px;
}
.right-site-map li a svg {
    fill: #f00;
    margin-right: 8px;
    position: relative;
    top: 3px;
}
#eviction-start h2{
font-size: 38px;
    margin-top: 25px;
	font-family: 'Poppins', sans-serif;
	letter-spacing:1px;
	font-weight:500;
	color:#000;
}
#eviction-start p{
	letter-spacing:1px;
	font-weight:500;
	font-family: 'Raleway', sans-serif;	
}
.left-eviction {
    border: 1px solid #f00;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 0px 8px 5px #d3d1d17a;
    margin-top: 25px;
    margin-bottom: 25px;
}
.right-eviction h3 {
    font-size: 27px;
}
.right-eviction p{
font-family: 'Raleway', sans-serif;	
	font-size:18px;
	margin-top:22px;
		font-weight:500;
}
.form-check label {
    font-size: 17px;
    color: #000;
    font-weight: 500;
	font-family: 'Raleway', sans-serif;
}
label.form-check-label {
    font-size: 18px;
    color: #000;
    font-weight: 600;
}
article#post-800 {
    margin-top: 35px;
}
article#post-843{
 margin-top: 35px;	
}
.about_help_section {
    margin-top: 111px !important;
}
.content-thumb h3 {
    font-size: 19px;
    margin-top: 13px;
    font-family: 'Poppins', sans-serif;
}
#testimonails-section .owl-nav {
    margin-top: 19px;
}
.call-section a {
    color: #f00;
}
html {
  scroll-behavior: smooth;
}
.mobile-menu ul.dropdown-menu li a {
    font-size: 18px !important;
    border-bottom: 1px dashed #cccccc4a;
	position:relative;
	margin-left:5px;
}

.mobile-menu ul.dropdown-menu li a:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 22px;
    background-color: #fff;
    height: 2px;
    width: 17px;
	margin-right:5px;
}
.pl{
	padding-left:0px;
}
div#living_trust h3 {
    margin-top: 4px;
}

.wpcf7 form.sent .wpcf7-response-output {
    border-color: #46b450;
    color: #fff;
}
/*-----------------------------------------------------------------------------------Testimonials------------------------------------------------------------------------*/
.review-sec {
    padding-top: 80px;
    font-family: raleway;
    font-size: 18px;
    font-weight: 500;
    color: #5E5D5D;
}

.sec-header {
    text-align: center;
    margin-bottom: 45px;
}

.sec-header h2 {
    width: fit-content;
    margin: auto;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    color: #114489;
    position: relative;
}

.sec-header h2::before {
    position: absolute;
    top: 50%;
    left: -13%;
    content: "";
    display: block;
    width: 51px;
    height: 2px;
    background-color: #114489;
}
.sec-header h2::after {
    position: absolute;
    top: 50%;
    right: -13%;
    content: "";
    display: block;
    width: 51px;
    height: 2px;
    background-color: #114489;
}

.sec-header p {
    width: 60%;
    margin: auto;
    margin-top: 15px;
}
.review-outer{
    height: 100%;
    border-radius: 15px;
    padding: 25px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.star-logo{
    display: flex;
    justify-content: space-between;
}
.star-logo ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 5px;
}
.star-logo ul img{
    width: 17px;
}
.star-logo div img{
    width: 25px;
}
.review{
    margin: 15px 0;
}
.person {
    display: flex;
    gap: 15px;
}
.image{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.name{
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0;
}
.designation{
    font-size: 15px;
}
.trust{
    padding-bottom: 80px;
}
.person_logo{
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    background: #114489;
    font-size: 26px;
    font-weight: 600;
    color: #fff;
    border-radius: 50%;
}
.reviewMoreButton{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 10px 30px;
    border-radius: 30px;
    font-size: 25px;
}
.reviewMoreButton img{
    width: 25px;
}
@media(min-width:768px) and (max-width:991px){
    .review-sec .row{
        justify-content: center;
        row-gap: 25px;
    }
    .sec-header h2::before {
        left: -45px;
        width: 35px;
    }
    .sec-header h2::after {
        right: -45px;
        width: 35px;
    }
}

@media(min-width:320px) and (max-width:767px){
    .review-sec {
        font-size: 16px;
    }
    .sec-header h2 {
        font-size: 25px;
    }
    .sec-header h2::before, .sec-header h2::after{
        display: none;
    }
    .sec-header p{
        width: 100%;
    }
    .review-sec .row{
        row-gap: 25px;
    }
    .name {
        font-size: 16px;
    }

}
.color-white, .contact-left-section h2, .contact-right-section h2, .price-section-left h2, #newsletter-section h2, section.arae-middle-section h2{
    color: #fff !important;
}
.eviction-otr img {
    margin-top: 19px;
    width: 100%;
    height: 323px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
}
.eviction-otr h2{
    line-height: 1;
    margin-bottom: 10px;
}