/*** 

====================================================================
	Header style One
====================================================================

***/

.main-header{
	position:relative;
	z-index:99;
	width:100%;
}

/* Header Top */

.main-header .header-top{
    position: relative;
    background-color: var(--main-color);
    border-bottom: 3px solid var(--main-color-two);
}

.main-header .header-top .inner-container{
	position:relative;
	padding-left:var(--padding-left-230);
}

.main-header .header-top .top-left{
	position: relative;
	float:left;
	padding:14px 0px;
}

.main-header .header-top .page-links{
	position: relative;
}

.main-header .header-top .page-links li{
	position: relative;
	display:inline-block;
	margin-right:var(--margin-right-15);
	padding-right:var(--padding-right-15);
	border-right:1px solid var(--white-color-opicity-two);
}

.main-header .header-top .page-links li a{
	position: relative;
	line-height:1.1em;
	padding:15px 0px;
	font-weight:500;
	color:var(--white-color);
	font-size:var(--font-14);
	text-transform:uppercase;
	font-family: 'Raleway', sans-serif;
}

.main-header .header-top .page-links li a:hover{
	color:#ec0404;
}

.main-header .header-top .page-links li:last-child{
	padding-right:var(--padding-zero);
	margin-right:var(--margin-zero);
	border:var(--border-zero);
}

.main-header .header-top .top-right{
	position: relative;
}

.main-header .header-top .top-right .social-box{
	position: relative;
	padding:13px 0px;
}

.main-header .header-top .top-right .social-box li{
	position: relative;
	margin-left:var(--margin-left-15);
	display:inline-block;
}

.main-header .header-top .top-right .social-box .follow{
	position:relative;
	font-weight:500;
	font-size:var(--font-14);
	color:var(--white-color);
	text-transform:uppercase;
	font-family: 'Raleway', sans-serif;
}

.main-header .header-top .top-right .social-box li a{
	position: relative;
	color:var(--white-color);
	font-size:var(--font-16);
}

.main-header .header-top .top-right .social-box li a:hover{
	color:#f00;
}

/* Header Top Two */

.header-top-two{
	position:relative;
	background-color:var(--main-color-two);
}

.header-top-two .inner-container{
	position:relative;
}

.header-top-two .top-left{
	position: relative;
	float:left;
}

.header-top-two .button-box{
	position:relative;
	float:left;
}

.header-top-two .button-box .contact-btn{
	position:relative;
	padding:14px 40px;
	font-weight:700;
	color:var(--main-color-two);
	background-color:var(--main-color);
	text-transform:uppercase;
}

.header-top-two .button-box .contact-btn:hover{
	color:var(--main-color-two);
	background-color:var(--white-color);
}

.header-top-two .info-links{
	position: relative;
	float:left;
	padding:14px 0px;
	margin-left:var(--margin-left-40);
}

.header-top-two .info-links li{
	position: relative;
	display:inline-block;
	color:var(--white-color);
	font-size:var(--font-14);
	padding-left:var(--padding-left-30);
	margin-right:var(--margin-right-15);
	padding-right:var(--padding-right-15);
	border-right:1px solid var(--white-color-opicity-two);
}

.header-top-two .info-links li .icon{
	position: absolute;
	left:0px;
	top:0px;
	font-weight:400;
	font-size:var(--font-16);
	color:var(--main-color);
}

.header-top-two .info-links li a{
	position: relative;
	line-height:1.1em;
	font-weight:600;
	color:var(--white-color);
	font-size:var(--font-14);
	text-transform:capitalize;
}

.header-top-two .info-links li a:hover{
	color:var(--main-color);
}

.header-top-two .info-links li:last-child{
	padding-right:var(--padding-zero);
	margin-right:var(--margin-zero);
	border:var(--border-zero);
}

.header-top-two .top-right{
	position: relative;
}

.header-top-two .top-right .social-box{
	position: relative;
	padding:13px 0px;
}

.header-top-two .top-right .social-box li{
	position: relative;
	margin-left:var(--margin-left-15);
	display:inline-block;
}

.header-top-two .top-right .social-box .follow{
	position:relative;
	font-weight:600;
	font-size:var(--font-14);
	color:var(--white-color);
	text-transform:uppercase;
	font-family:var(--font-family-fira);
}

.header-top-two .top-right .social-box li a{
	position: relative;
	color:var(--white-color);
	font-size:var(--font-16);
}

.header-top-two .top-right .social-box li a:hover{
	color:var(--main-color);
}

/* Header Top Three */

.header-top-three{
	position:relative;
	background-color:var(--main-color-two);
}

.header-top-three .inner-container{
	position:relative;
}

.header-top-three .top-left{
	position: relative;
	float:left;
}

.header-top-three .button-box{
	position:relative;
	float:left;
}

.header-top-three .button-box .contact-btn{
	position:relative;
	padding:14px 40px;
	font-weight:700;
	color:var(--main-color-two);
	background-color:var(--main-color);
	text-transform:uppercase;
}

.header-top-three .button-box .contact-btn:hover{
	color:var(--main-color-two);
	background-color:var(--white-color);
}

.header-top-three .text{
	position: relative;
	font-size:16px;
	opacity:0.90;
	float:left;
	padding:11.5px 0px;
	color:var(--white-color);
	margin-left:var(--margin-left-30);
}

.header-top-three .top-right{
	position: relative;
}

.header-top-three .top-right .search-box-btn{
	width:50px;
	height:50px;
	line-height:50px;
	border-radius:0px;
	text-align:center;
	cursor:pointer;
	display:inline-block;
	font-size:var(--font-18);
	color:var(--main-color-two);
	margin-top:var(--margin-zero);
	background-color:var(--main-color);
}

.header-top-three .top-right .search-box-btn:hover{
	color:var(--main-color-two);
	background-color:var(--white-color);
}

.header-top-three .top-right .social-box{
	position: relative;
	padding:13px 0px;
	float:left;
	margin-left:var(--margin-left-20);
}

.header-top-three .top-right .social-box li{
	position: relative;
	display:inline-block;
	margin-left:var(--margin-left-15);
}

.header-top-three .top-right .social-box li a{
	position: relative;
	color:var(--white-color);
	font-size:var(--font-16);
}

.header-top-three .top-right .social-box li a:hover{
	color:var(--main-color);
}




.main-header .header-upper{
	position:relative;
	background-color:var(--white-color);
}

.main-header .header-upper .upper-right{
	position:relative;
	padding-top:var(--padding-top-25);
}

.main-header .info-box{
	position:relative;
	float:left;
	z-index:1;
	min-height:56px;
	margin-left:var(--margin-left-40);
	font-size:var(--font-16);
	color:#777777;
	text-align:left;
	padding:0px 0px 10px 55px;
}

.main-header .info-box .icon-box{
	position:absolute;
	left:0px;
	top:3px;
	line-height:1em;
	font-size:var(--font-40);
	-moz-transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	transition:all 300ms ease;
	color:var(--main-color);
	line-height:1em;
}

.main-header .info-box ul li{
	position:relative;
	line-height:1.6em;
	font-weight:500;
	margin:var(--margin-zero);
	color:var(--main-color);
	font-size:var(--font-16);
	text-transform:capitalize;
	font-family: 'Raleway', sans-serif;
	font-weight:600;
}

.main-header .info-box ul li a{
	position:relative;
	color:var(--main-color-two);
    font-size: var(--font-15);
	font-family: 'Raleway', sans-serif;
	font-weight:500;
}

.main-header .info-box ul li a:hover{
	color:var(--main-color);
}

.main-header .info-box strong{
	position:relative;
	font-weight:700;
	font-size:var(--font-18);
	text-transform:uppercase;
	color:var(--main-color-two);
	font-family:var(--font-family-inter);
}

.main-header .info-box:last-child{
	top:-5px;
	padding-left:var(--padding-zero);
}

.main-header .main-box{
	position:relative;
	padding:0px 0px;
	left:0px;
	top:0px;
	width:100%;
	background:none;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-header .main-box .outer-container{
	position:relative;
	padding:0px 40px;
}

.main-header .main-box .logo-box{
	position:relative;
	float:left;
	left:0px;
	z-index:10;
	padding:30px 0px;
}

.main-header .main-box .logo-box .logo img{
	display:inline-block;
	max-width:100%;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;	
}

.main-header .header-lower{
	position:absolute;
	left:0px;
	right:0px;
	top:100%;
}

.main-header .header-lower .nav-outer{
	position:relative;
	padding-left:var(--padding-left-220);
}

.main-header .header-lower .nav-outer:before{
	position:absolute;
	content:'';
	left:170px;
	top:0px;
	bottom:0px;
	width:200%;
	background-color:#F3012A;
}

.main-header .header-lower .nav-outer .curve-layer{
	position:absolute;
	content:'';
	left:0px;
	top:19px;
	border-top: 51px solid var(--main-color-two);
    border-left: 170px solid transparent;
}

.main-header .header-upper .logo-box{
    position: relative;
    margin-top: -53px;
    margin-bottom: -20px;
    padding-bottom: var(--padding-bottom-15);
    background-color: var(--white-color);
}

.main-header .header-upper .logo-box .logo{
	position:relative;
    max-width: 170px;
    padding: 20px;
    margin: 0 auto;
}

.main-header .header-upper .logo-box .logo:before{
	position:absolute;
	content:'';
	left:0px;
	bottom:-50px;
	z-index:1;
	border-top: 36px solid var(--white-color);
    border-left: 170px solid transparent;
}

.main-header .main-menu .navigation > li:nth-child(7) > ul,
.main-header .main-menu .navigation > li:nth-child(8) > ul{
	left:0px;
	right:auto !important;
}

/* Main Menu */

.main-header .main-menu{
	position:relative;
	float:left;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-header .main-menu .navbar-collapse{
	padding:0px;
	float: left;
	display:block !important;
}

.main-header .main-menu .navigation{
	position:relative;
	margin:0px;
}

.main-header .main-menu .navigation > li{
	position:relative;
	float:left;
	margin-right:var(--margin-right-30);
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	border-left:1px solid var(--light-border-color);
}

.main-header .main-menu .navigation > li:last-child{
	border-right:1px solid var(--light-border-color);
}

.main-header .sticky-header .main-menu .navigation > li > a{
	
}

.main-header .sticky-header .auto-container{
	max-width:1200px !important;
}

.main-header .sticky-header .main-menu .navigation > li:hover > a,
.main-header .sticky-header .main-menu .navigation > li.current > a{
	color:var(--main-color);
	background-color:inherit !important;
}

.main-header .sticky-header .nav-outer .options-box{
	margin-top:var(--margin-top-40);
}

/*Sticky Header*/

.main-header .sticky-header{
	position:fixed;
	opacity:0;
	visibility:hidden;
	left:0px;
	top:0px;
	width:100%;
	padding:0px 0px;
	z-index:0;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	background-color:var(--main-color-two);
	box-shadow:0px 0px 10px rgba(0,0,0,0.10);
}

.main-header .sticky-header .mobile-nav-toggler{
	display:none;
	cursor:pointer;
	color:var(--black-color);
	font-size:var(--font-30)
}

.main-header .sticky-header .logo{
	padding:0px 0px;
}

.main-header.fixed-header .sticky-header{
	z-index:999;
	opacity:1;
	visibility:visible;
	-ms-animation-name: fadeInDown;
	-moz-animation-name: fadeInDown;
	-op-animation-name: fadeInDown;
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	-ms-animation-duration: 500ms;
	-moz-animation-duration: 500ms;
	-op-animation-duration: 500ms;
	-webkit-animation-duration: 500ms;
	animation-duration: 500ms;
	-ms-animation-timing-function: linear;
	-moz-animation-timing-function: linear;
	-op-animation-timing-function: linear;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-ms-animation-iteration-count: 1;
	-moz-animation-iteration-count: 1;
	-op-animation-iteration-count: 1;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;	
}

.main-header .main-menu .navigation > li > a{
	position:relative;
	display:block;
	text-align:center;
	line-height:30px;
	letter-spacing:0px;
	opacity:1;
	font-weight:600;
	padding:20px 0px;
	font-size:var(--font-15);
	color:var(--white-color);
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.main-header .main-menu .navigation > li > a:before{
	position:absolute;
	content:'';
	left:0px;
	bottom:0px;
	right:0px;
	height:3px;
	opacity:0;
	background-color:var(--main-color-three);
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	transform:scale(0.2,1);
}

.main-header .main-menu .navigation > li:hover > a:before,
.main-header .main-menu .navigation > li.current > a:before{
	opacity:1;
	transform:scale(1,1);
}

.main-header .main-menu .navigation > li:last-child{
	margin-right:var(--margin-zero);
}

.main-header .main-menu .navigation > li:last-child > a{
	padding-right:0px;
}

.main-header .main-menu .navigation > li.dropdown > a{
	
}

.main-header .sticky-header .main-menu .navigation > li{
	position:relative;
}

.main-header .sticky-header .main-menu .navigation > li:last-child{
	margin-right:var(--margin-zero);
}

.main-header .main-menu .navigation > li:last-child ul{
	right:0px;
}

.main-header .main-menu .navigation > li:hover > a,
.main-header .main-menu .navigation > li.current > a{
	opacity:1;
	color:var(--main-color-three);
}

.main-header .main-menu .navigation > li > ul{
	position:absolute;
	width:15rem;
	-webkit-transform: scaleY(0);
	-ms-transform: scaleY(0);
	-o-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transform-origin: top;
	-ms-transform-origin: top;
	-o-transform-origin: top;
	transform-origin: top;
	opacity: 0;
	visibility: hidden;
    visibility: hidden;
    transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
	border-radius:0px;
	background-color:var(--main-color-two);
	box-shadow: 0px 0px 10px rgba(0,0,0,0.10);
}

.main-header .main-menu .navigation > li > ul.from-right{
	left:auto;
	right:0px;	
}

.main-header .main-menu .navigation > li > ul > li{
	position:relative;
	width:100%;
	text-align:left;
	border-bottom:1px solid rgba(255,255,255,0.10);
}

.main-header .main-menu .navigation > li > ul > li:last-child{
	border-bottom:none;
}

.main-header .main-menu .navigation > li > ul > li > a{
	position:relative;
	display:block;
	padding:10px 18px;
	line-height:24px;
	font-weight:500;
	font-size:var(--font-14);
	text-transform:uppercase;
	color:var(--white-color);
}

.main-header .main-menu .navigation > li > ul > li > a:before{
	position:absolute;
	content:'//';
	left:10px;
	top:11px;
	opacity:0;
	color:var(--main-color);
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .main-menu .navigation > li > ul > li:hover > a::before{
	opacity:1;
}

.main-header .sticky-header .main-menu .navigation > li > a:before{
	
}

.main-header .main-menu .navigation > li > ul > li:hover > a{
	padding-left:30px;
	color:var(--main-color);
}

.main-header .main-menu .navigation > li > ul > li.dropdown > a:after{
	font-family: 'FontAwesome';
	content: "\f105";
	position:absolute;
	right:8px;
	top:13px;
	width:10px;
	height:20px;
	display:block;
	color:var(--white-color);
	line-height:20px;
	font-size:var(--font-16);
	font-weight:normal;
	text-align:center;
	z-index:5;	
}

.main-header .main-menu .navigation > li > ul > li > ul{
	position:absolute;
	left:100%;
	top:0px;
	width:15rem;
	-webkit-transform: scaleY(0);
	-ms-transform: scaleY(0);
	-o-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transform-origin: top;
	-ms-transform-origin: top;
	-o-transform-origin: top;
	transform-origin: top;
	opacity: 0;
	visibility: hidden;
    visibility: hidden;
    transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
	border-radius:0px;
	background-color:var(--main-color-two);
	box-shadow: 0px 0px 10px rgba(0,0,0,0.10);
}

.main-header .main-menu .navigation > li > ul > li > ul.from-right{
	left:auto;
	right:0px;	
}

.main-header .main-menu .navigation > li > ul > li > ul > li{
	position:relative;
	width:100%;
	text-align:left;
	border-bottom:1px solid rgba(255,255,255,0.10);
}

.main-header .main-menu .navigation > li > ul > li > ul > li:last-child{
	border-bottom:none;	
}

.main-header .main-menu .navigation > li > ul > li > ul > li > a{
	position:relative;
	display:block;
	padding:10px 18px;
	line-height:24px;
	font-weight:700;
	font-size:var(--font-14);
	text-transform:uppercase;
	color:var(--white-color);
}

.main-header .main-menu .navigation > li > ul > li > ul > li:hover > a{
	color:var(--main-color);
}

.main-header .main-menu .navigation > li > ul > li > ul > li > a:before{
	position:absolute;
	content:'//';
	left:10px;
	top:11px;
	opacity:0;
	color:var(--main-color);
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .main-menu .navigation > li > ul > li > ul > li:hover > a::before{
	opacity:1;
}

.main-header .main-menu .navigation > li > ul > li > ul > li:hover > a{
	color:var(--main-color);
	padding-left:30px;
}

.main-header .main-menu .navigation > li.dropdown:hover > ul{
	-webkit-transform: scaleY(1);
	-ms-transform: scaleY(1);
	-o-transform: scaleY(1);
	transform: scaleY(1);
	opacity: 1;
	visibility: visible;
}

.main-header .main-menu .navigation li > ul > li.dropdown:hover > ul{
	-webkit-transform: scaleY(1);
	-ms-transform: scaleY(1);
	-o-transform: scaleY(1);
	transform: scaleY(1);
	opacity: 1;
	visibility: visible;
}

.main-header .main-menu .navbar-collapse > ul li.dropdown .dropdown-btn{
	position:absolute;
	right:10px;
	top:6px;
	width:30px;
	height:30px;
	text-align:center;
	color:var(--white-color);
	line-height:28px;
	border:1px solid var(--white-color-opicity-two);
	background-size:20px;
	cursor:pointer;
	z-index:5;
	display:none;
}

.main-menu .navigation > li > ul > li.updates{
	padding:12px 20px;
	color:#ffffff;
	left:0px;
	right:0px;
	width:auto;
	font-weight:700;
	font-size:var(--font-14);
	text-transform:uppercase;
	background-color:var(--main-color);
}

/*** 

====================================================================
	Mobile Menu
====================================================================

***/

.main-header .nav-outer .mobile-nav-toggler{
	position: relative;
	float: right;
	padding:2px 0 0px;
	font-size: var(--font-24);
	line-height: 44px;
	cursor: pointer;
	color:var(--black-color);
	display: none;
}

.mobile-menu{
	position: fixed;
	right: 0;
	top: 0;
	width: 300px;
	padding-right:30px;
	max-width:100%;
	height: 100%;
	visibility: hidden;
	z-index: 999999;
}

.mobile-menu .nav-logo{
	position:relative;
	padding:20px 20px;
	text-align:left;	
}

.mobile-menu .nav-logo img{
	max-width:200px;
}

.mobile-menu-visible{
	overflow: hidden;
}

.mobile-menu-visible .mobile-menu{
	visibility: visible;
}

.mobile-menu .menu-backdrop{
	position: fixed;
	right: 0;
	top: 0;
	width: 0%;
	height: 100%;
	z-index: 1;
	background: rgba(0,0,0,0.90);
	-webkit-transform: translateX(101%);
	-ms-transform: translateX(101%);
	transform: translateX(101%);
	transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
	
	-webkit-transition-delay: 300ms;
	-moz-transition-delay: 300ms;
	-ms-transition-delay: 300ms;
	-o-transition-delay: 300ms;
	transition-delay: 300ms;
}

.mobile-menu-visible .mobile-menu .menu-backdrop{
	opacity: 1;
	width:100%;
	visibility: visible;
	transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
	-webkit-transform: translateX(0%);
	-ms-transform: translateX(0%);
	transform: translateX(0%);
}

.mobile-menu .menu-box{
	position: absolute;
	right: -400px;
	top: 0px;
	width: 100%;
	height: 100%;
	max-height: 100%;
	overflow-y: auto;
	background: #ffffff;
	padding: 0px 0px;
	z-index: 5;
	border-radius: 0px;
	
	transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
}

.mobile-menu-visible .mobile-menu .menu-box{
	right:0px;
	-webkit-transition-delay: 600ms;
	-moz-transition-delay: 600ms;
	-ms-transition-delay: 600ms;
	-o-transition-delay: 600ms;
	transition-delay: 600ms;
}

.mobile-menu .close-btn{
	position: absolute;
	right: 15px;
	top: 15px;
	line-height: 30px;
	width: 30px;
	text-align: center;
	font-size: 14px;
	color: #202020;
	cursor: pointer;
	z-index: 10;
	-webkit-transition:all 0.5s ease;
	-moz-transition:all 0.5s ease;
	-ms-transition:all 0.5s ease;
	-o-transition:all 0.5s ease;
	transition:all 0.5s ease;
	-webkit-transform: translateY(-50px);
	-ms-transform: translateY(-50px);
	transform: translateY(-50px);
}

.mobile-menu-visible .mobile-menu .close-btn{
	-webkit-transform: translateY(0px);
	-ms-transform: translateY(0px);
	transform: translateY(0px);
	-webkit-transition-delay: 900ms;
	-moz-transition-delay: 900ms;
	-ms-transition-delay: 900ms;
	-o-transition-delay: 900ms;
	transition-delay: 900ms;
}

.mobile-menu .close-btn:hover{
	opacity: 0.50;
}

.mobile-menu .navigation{
	position: relative;
	display: block;
	width:100%;
	border-top: 1px solid rgba(0,0,0,0.10);
}

.mobile-menu .navigation li{
	position: relative;
	display: block;
	border-bottom: 1px solid rgba(0,0,0,0.10);
}

.mobile-menu .navigation li > ul > li:last-child{
	border-bottom: none;
}

.mobile-menu .navigation li > ul > li:first-child{
	border-top: 1px solid rgba(0,0,0,0.10);
}

.mobile-menu .navigation li > a{
	position: relative;
	display: block;
	line-height: 24px;
	padding: 10px 20px;
	font-size: 15px;
	color: #404040;
	text-transform: capitalize;
}

.mobile-menu .navigation li:hover > a,
.mobile-menu .navigation li.current > a{
	color:#111111;
}

.mobile-menu .navigation li.dropdown .dropdown-btn{
	position:absolute;
	right:0px;
	top:0px;
	width:44px;
	height:44px;
	text-align:center;
	font-size:16px;
	line-height:44px;
	color:#404040;
	cursor:pointer;
	z-index:5;
}

.mobile-menu .navigation li.dropdown .dropdown-btn:after{
	content:'';
	position:absolute;
	left:0px;
	top:10px;
	width:1px;
	height:24px;
	border-left:1px solid rgba(0,0,0,0.10);
}

.mobile-menu .navigation li > ul,
.mobile-menu .navigation li > ul > li > ul{
	display: none;
}

.mobile-menu .navigation li > ul > li > a{
	padding-left:30px;
}

.mobile-menu .navigation li > ul > li > ul{
	padding-left:0px;
}

.mobile-menu .navigation li > ul > li > ul > li > a{
	padding-left:40px;
}

.main-header .mobile-menu .close-btn:hover{
	opacity: 0.50;
}

.main-header .mobile-menu .navigation{
	position: relative;
	display: block;
	width:100%;
	border-top: 1px solid #dddddd;
}

.main-header .mobile-menu .navigation li{
	position: relative;
	display: block;
	border-bottom:1px solid #dddddd00;
}

.main-header .mobile-menu .navigation li > ul > li:last-child{
	border-bottom: none;
}

.main-header .mobile-menu .navigation li > ul > li:first-child{
	border-top: 1px solid #dddddd;
}

.main-header .mobile-menu .navigation li > a{
	position: relative;
	display: block;
	line-height: 24px;
	padding: 10px 20px;
	font-size: var(--font-16);
	color: var(--dark-color);
	text-transform: capitalize;
}

.main-header .mobile-menu .navigation li:hover > a,
.main-header .mobile-menu .navigation li.current > a{
	color: var(--dark-text-color-two);
}

.main-header .mobile-menu .navigation li.dropdown .dropdown-btn{
	position:absolute;
	right:0px;
	top:0px;
	width:44px;
	height:44px;
	text-align:center;
	color: var(--dark-color);
	font-size: var(--font-16);
	line-height:44px;
	cursor:pointer;
	z-index:5;
}

.main-header .mobile-menu .navigation li.dropdown .dropdown-btn:after{
	content:'';
	position:absolute;
	left:0px;
	top:10px;
	width:1px;
	height:24px;
	border-left:1px solid #dddddd;
}

.main-header .mobile-menu .navigation li > ul,
.main-header .mobile-menu .navigation li > ul > li > ul{
	display: none;
}

.main-header .sticky-header .navbar-header{
	display:none;
}

.main-header .outer-box{
	position: relative;
	float:right;
	padding: 10px 0px;
}

/* Search Box Outer */

.main-header .search-box-outer{
	position:relative;
	float:left;
}

.main-header .header-lower .search-box-btn{
	position:relative;
	top: 0px;
	display:block;
	float:left;
	font-size:var(--font-20);
	color:var(--white-color);
	line-height:20px;
	cursor:pointer;
	margin-top:var(--margin-top-15);
	background:none;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .outer-box .nav-btn{
	position:relative;
	float:left;
	width:50px;
	height:50px;
	cursor:pointer;
	line-height:50px;
	text-align:center;
	font-size:var(--font-18);
	color:var(--white-color);
	margin-left:var(--margin-left-25);
	background-color:var(--main-color);
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .header-lower .search-box-btn:hover{
	color:var(--white-color);
}

.main-header .outer-box .nav-btn:hover{
	background-color:var(--white-color);
	color:var(--main-color-two);
}

.main-header.header-style-two .header-upper .logo-box{
	margin:0px;
	padding:5px 0px;
	background-color:var(--white-color);
}

.main-header.header-style-two .header-upper .logo-box .logo:before,
.main-header.header-style-two .header-upper .logo-box:before{
	display:none;
}

.main-header.header-style-two .header-upper .nav-outer{
	float:right;
}

.main-header.header-style-two .main-menu .navigation > li{
	margin-left:var(--margin-left-30);
	margin-right:var(--zero-margin);
}

.main-header.header-style-two .main-menu .navigation > li > a{
	padding:25px 0px;
	text-transform:capitalize;
}

.main-header.header-style-two .main-menu .navigation > li:hover > a,
.main-header.header-style-two .main-menu .navigation > li.current > a{
	color:var(--main-color);
}

.main-header.header-style-two .search-box-btn{
	width:50px;
	height:50px;
	line-height:50px;
	border-radius:0px;
	text-align:center;
	cursor:pointer;
	display:inline-block;
	font-size:var(--font-18);
	color:var(--main-color-two);
	margin-top:var(--margin-zero);
	background-color:var(--main-color);
}

.main-header.header-style-two .outer-box{
	padding:15px 0px;
	margin-left:var(--margin-left-30);
}

.main-header.header-style-two .outer-box .nav-btn{
	margin-left:var(--margin-left-20);
}

.main-header.header-style-two .sticky-header .logo{
	padding:5px 0px;
}

/* Header Style Three */

.main-header.header-style-three{
	position:absolute;
	left:0px;
	top:0px;
	right:0px;
}

.main-header.header-style-three .auto-container{
	max-width:1800px;
}

.main-header.header-style-three .header-upper{
	background:none;
	border-bottom:1px solid rgba(255,255,255,0.50);
}

.main-header.header-style-three .header-upper .nav-outer .main-menu{
	position:relative;
	padding-left:var(--padding-left-40);
}

.main-header.header-style-three .outer-box .cart-box{
	margin-right:var(--margin-zero);
}

.main-header.header-style-three .header-upper .logo-box{
	margin-top:0px;
	background:none;
	padding:20px 20px 20px 0px;
	border-right:1px solid rgba(255,255,255,0.50);
}

.main-header.header-style-three .header-upper .logo-box .logo:before,
.main-header.header-style-three .header-upper .logo-box:before{
	display:none;
}

.main-header.header-style-three .main-menu .navigation > li > a{
	padding:35px 0px;
	color:var(--white-color);
	text-transform:capitalize;
}

.main-header.header-style-three .outer-box{
	padding:25px 0px;
	float:right;
}

.main-header.header-style-three .info-box:last-child{
	padding-left:var(--padding-left-55);
}

.main-header.header-style-three .upper-info{
	position:relative;
	padding:22px 0px;
	overflow:hidden;
	margin-right:var(--margin-right-30);
	border-left:1px solid rgba(255,255,255,0.50);
}

.main-header.header-style-three .info-box{
	padding-bottom:var(--padding-zero);
	padding-right:var(--padding-right-25);
}

.main-header.header-style-three .info-box:before{
	position:absolute;
	content:'';
	right:1px;
	top:-20px;
	width:1px;
	bottom:-25px;
	opacity:0.50;
	background-color:var(--white-color);
}

.main-header.header-style-three .info-box ul li a{
	color:var(--white-color);
}

.main-header.header-style-three .sticky-header .main-menu .navigation > li > a{
	padding:20px 0px;
	color:var(--main-color-two);
}

.main-header.header-style-three .sticky-header .outer-box{
	padding:10px 0px;
	margin-left:var(--margin-left-30);
}

.header-style-four .auto-container{
	max-width:1800px;
}

.header-style-four .header-upper .logo-box{
	padding:0px 0px;
	background:none;
	margin:0px;
}

.header-style-four .header-top-two .info-links{
	margin-left:var(--margin-zero);
}

.header-style-four .header-upper .logo-box .logo:before{
	display:none;
}

.header-style-four .header-upper .nav-outer .main-menu{
	padding-left:var(--padding-left-40);
}

.main-header.header-style-four .main-menu .navigation > li > a{
	text-transform:capitalize;
}

.main-header.header-style-four .main-menu .navigation > li:hover > a,
.main-header.header-style-four .main-menu .navigation > li.current > a{
	color:var(--main-color);
}

.main-header.header-style-four .search-box-btn{
	width:50px;
	height:50px;
	line-height:50px;
	border-radius:0px;
	text-align:center;
	cursor:pointer;
	display:inline-block;
	font-size:var(--font-18);
	color:var(--main-color-two);
	margin-top:var(--margin-zero);
	background-color:var(--main-color);
}

.header-style-four .header-upper .outer-box .button-box{
	float:left;
	display: block;
	padding:0px 0px;
	margin-top:-10px;
	margin-top: -10px;
    margin-bottom: -10px;
	margin-left:25px;
}

.header-style-four .header-upper .outer-box .button-box .theme-btn{
	border-radius:0px;
	padding:20px 42px;
}

.main-header.header-style-four .outer-box .nav-btn:hover{
	background-color:var(--main-color);
}

/* Cart Box */

.main-header .outer-box .cart-box{
	position:relative;
	float:left;
	top:-2px;
	background:none;
	display:inline-block;
	color:var(--white-color);
	font-size:var(--font-18);
	margin-top:var(--margin-top-20);
	margin-right:var(--margin-right-25);
}

.main-header .outer-box .cart-box .cart-box-btn{
	background:none;
	cursor: pointer;
	color:var(--main-color);
	font-size:var(--font-24);
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.main-header .outer-box .cart-box .cart-box-btn:after{
	display:none;
}

.main-header .outer-box .cart-box .cart-box-btn .total-cart{
	position:absolute;
	right: -8px;
	top: -8px;
	width:18px;
	height:18px;
	color:var(--white-color);
	font-size:var(--font-12);
	font-weight:700;
	border-radius:50%;
	text-align:center;
	line-height:18px;
	background-color:var(--main-color-two);
}

.main-header .outer-box .cart-box .cart-box-btn:hover{
	color:var(--main-color-two);
}

.main-header .outer-box .cart-product{
	position:relative;
	min-height:90px;
	border-bottom:1px solid #e4e4e4;
	margin-bottom:var(--margin-bottom-20);
}

.main-header .outer-box .cart-product .inner{
	position:relative;
	min-height:70px;
	padding-left:var(--padding-left-80);
}

.main-header .outer-box .cart-product .inner .cross-icon{
	position:absolute;
	right:0px;
	top:-2px;
	z-index:1;
	color:var(--main-color-two);
	font-size:var(--font-16);
	cursor:pointer;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .outer-box .cart-product .inner .cross-icon:hover{
	color:var(--main-color);
}

.main-header .outer-box .cart-product .inner .image{
	position:absolute;
	left:0px;
	top:0px;
	overflow:hidden;
	border-radius:50px;
}

.main-header .outer-box .cart-product .inner .image img{
	width:100%;
	display:block;
	width:66px;
	height:66px;
}

.main-header .outer-box .cart-product .inner h3{
	position:relative;
	font-weight:600;
	font-size:var(--font-15);
	margin:var(--zero-margin);
	letter-spacing: 1px;
	text-transform:capitalize;
}

.main-header .outer-box .cart-product .inner h3 a{
	color:var(--main-color-two);
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.main-header .outer-box .cart-product .inner h3 a:hover{
	color:var(--main-color);
}

.main-header .outer-box .cart-product .inner .quantity-text{
	position:relative;
	font-weight:300;
	color:var(--main-color-two);
	font-size:var(--font-15);
}

.main-header .outer-box .cart-product .inner .price{
	position:relative;
	font-weight:700;
	margin:0px;
	font-size:var(--font-15);
	color:var(--main-color-two);
	margin-top:var(--margin-top-5);
}

.main-header .outer-box .cart-box .cart-panel{
	left: auto !important;
	right: 0px !important;
	border-radius:10px;
	padding:20px 20px;
	min-width:300px;
	margin-top:48px;
	z-index: 1;
	border:0px;
	box-shadow: 0px 0px 25px rgba(0,0,0,0.15);
	-webkit-transform: translate3d(0px, 0px, 0px) !important;
	transform: translate3d(0px, 0px, 0px) !important;
}

.main-header .outer-box .cart-box .cart-panel .cart-total{
	color:var(--main-color-two);
	font-size:var(--font-16);
	font-weight:400;
	text-align:center;
	text-transform:capitalize;
}

.main-header .outer-box .cart-box .cart-panel .cart-total span{
	font-weight:800;
	font-size:var(--font-18);
}

.main-header .outer-box .cart-box .cart-panel .btns-boxed{
	position:relative;
	text-align:center;
	margin-top:var(--margin-top-20);
}

.main-header .outer-box .cart-box .cart-panel .btns-boxed li{
	position:relative;
	margin:0px 5px;
	display:inline-block;
}

.main-header .outer-box .cart-box .cart-panel .btns-boxed li a{
	position:relative;
	color:var(--white-color);
	font-weight:600;
	font-size:var(--font-12);
	border-radius:50px;
	padding:8px 20px 7px;
	border-radius: 50px;
	display:inline-block;
	background-color:var(--main-color-two);
	border:2px solid var(--main-color-two);
	text-transform:uppercase;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.main-header .outer-box .cart-box .cart-panel .btns-boxed li a:hover{
	color:var(--main-color-two);
	background:none;
}

.main-header .outer-box .cart-box .icon a{
	color:var(--main-color-two);
}

/*** 

====================================================================
	Search Popup
====================================================================

***/


.search-popup{
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100%;
	z-index: 99999;
	margin-top: -540px;
	transform: translateY(-100%);
	background-color: rgba(0,0,0,0.90);
    -webkit-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition: all 1500ms cubic-bezier(0.860, 0.000, 0.070, 1.000); /* easeInOutQuint */
    -webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000); /* easeInOutQuint */
}

.sidenav-bar-visible .search-popup{
	width: 80%;
}

.search-popup:before{
	position: absolute;
	left: 0;
	top: 100%;
	width: 100%;
	height: 560px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	margin-top: 0px;
	content: "";
}

.search-active .search-popup{
	transform: translateY(0%);
	margin-top:var(--margin-zero);
}

.search-popup .close-search{
	position: absolute;
	left: 0;
	right: 0;
	top: 75%;
	margin: 0 auto;
	margin-top: -200px;
	border-radius: 50%;
	text-align: center;
	background-color:var(--main-color);
	width: 70px;
	cursor:pointer;
	border-bottom: 3px solid var(--white-color);
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
	opacity: 0;
	visibility: hidden;
}

.search-popup .close-search span{
	position: relative;
	display: block;
	height: 70px;
	width: 70px;
	font-size:var(--font-30);
	line-height: 70px;
	color: var(--white-color);
}

.search-active .search-popup .close-search{
	visibility: visible;
	opacity: 1;
	top: 50%;
	-webkit-transition-delay: 1500ms;
	-moz-transition-delay: 1500ms;
	-ms-transition-delay: 1500ms;
	-o-transition-delay: 1500ms;
	transition-delay: 1500ms;
}

.search-popup form{
	position: absolute;
	max-width: 700px;
	top: 50%;
	left: 15px;
	right: 15px;
	margin:-35px auto 0;
	transform: scaleX(0);
	transform-origin: center;
	background-color: var(--dark-color);
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}

.search-active .search-popup form{
	transform: scaleX(1);
	-webkit-transition-delay: 1200ms;
	-moz-transition-delay: 1200ms;
	-ms-transition-delay: 1200ms;
	-o-transition-delay: 1200ms;
	transition-delay: 1200ms;
}

.search-popup .form-group{
	position:relative;
	margin:var(--margin-zero);
	overflow: hidden;
}

.search-popup .form-group input[type="text"],
.search-popup .form-group input[type="search"]{
	position:relative;
	display:block;
	font-size:var(--font-18);
	line-height: 50px;
	color:var(--black-color);
	height:70px;
	width:100%;
	padding: 10px 30px;
	background-color: var(--white-color);
	-webkit-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	transition:all 500ms ease;
	font-weight:500;
	text-transform:capitalize;
}

.search-popup .form-group input[type="submit"],
.search-popup .form-group button{
	position:absolute;
	right:30px;
	top:0px;
	height:70px;
	line-height: 70px;
	background: transparent;
	text-align:center;
	font-size:var(--font-24);
	color:var(--black-color);
	padding: 0;
	cursor:pointer;
	-webkit-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	transition:all 500ms ease;
}

.search-popup .form-group input[type="submit"]:hover,
.search-popup .form-group button:hover{
	color: var(--black-color);
}

.search-popup input::placeholder,
.search-popup textarea::placeholder{
	color: var(--black-color);
}

.search-popup .close-search.style-two{
	position: absolute;
	right: 25px;
	left: auto;
	color: var(--white-color);
	width:auto;
	height:auto;
	top:25px;
	margin:0px;
	border:none;
	background:none !important;
	box-shadow:none !important;
	-webkit-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	transition:all 500ms ease;
}

.search-popup .close-search.style-two span{
	font-size:var(--font-20);
	color: var(--white-color);
}

.main-header .sticky-header .search-box-btn{
	color: var(--black-color);
	margin-top:var(--margin-top-10);
}

.main-header .mobile-menu .menu-box .mCSB_scrollTools{
	width:3px;
}

/*** 

====================================================================
	Hidden Sidebar style
====================================================================

***/

.sidebar-info-contents{
	position:relative;
}

.sidebar-info-contents .content-inner{
	position:relative;
}

.sidebar-info-contents .content-inner .logo {
	padding:0px 0px 40px;
}

.sidebar-info-contents .content-inner .logo img{
	display:inline-block;
	max-width:100%;	
}

.sidebar-info-contents .content-inner .content-box{
	position:relative;
}

.sidebar-info-contents .content-inner .content-box h4{
	font-weight:500;
	color:var(--white-color);
	margin-bottom:var(--margin-top-20);
}

.sidebar-info-contents .content-inner .content-box .text{
	position:relative;
	line-height:1.7em;
	font-size:var(--font-16);
	color:rgba(255,255,255,0.90);
	margin-bottom:var(--margin-top-25);
}

.sidebar-info-contents .content-inner .contact-info{
	position:relative;
	margin-top:var(--margin-top-60);
}

.sidebar-info-contents .content-inner .contact-info h4{
	color:var(--white-color);
	font-weight:500;
	margin-bottom:var(--margin-top-20);
}

.sidebar-info-contents .content-inner .social-box{
	position:relative;
	margin-top:var(--margin-top-20);
	margin-bottom:var(--margin-top-30);
}

.sidebar-info-contents .content-inner .social-box li{
	position:relative;
	display:inline-block;
	margin-right:var(--margin-right-5);
	transition: all 900ms ease;
    -moz-transition: all 900ms ease;
    -webkit-transition: all 900ms ease;
    -ms-transition: all 900ms ease;
    -o-transition: all 900ms ease;
}

.sidebar-info-contents .content-inner .social-box li a{
	position:relative;
	width:36px;
	height:36px;
	color:var(--main-color-two);
	z-index:1;
	font-size:var(--font-14);
	line-height:36px;
	text-align:center;
	border-radius:50%;
	display:inline-block;
	background-color:var(--white-color);
	-webkit-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	transition:all 500ms ease;
}

.sidebar-info-contents .content-inner .social-box li a:hover{
	color:var(--white-color);
	background-color:var(--main-color);
}

.close-side-widget,
.close-side-widget:hover{
	color:var(--white-color) !important;
	font-size:var(--font-24);
}
.sticky-header .logo img{width: 70px;}