/* Bricks Construction HTML Template */

/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global
4. Main Header / Style Two / Style Three
5. Banner Section / Style Two / Style Three / Style Four
6. 
7. 
8. 
9. 
10. 
11. 
12. 
13. 
14. 
15. 
16. 
17. 
18. 
19. 
20. 
21. 
22. 
23. 
24. 
25. 
26. 
27. 
28. 
29. 
30. 
31. 
32. 
33. 
34. 
35. 
36. 
37. 
38. 
39. 
40. 

**********************************************/

/* 

	font-family: "Inter", sans-serif;
	font-family: "Nunito Sans", sans-serif;
	
*/

/*** 

====================================================================
	Root Code Variables
====================================================================

 ***/

/* Theme Color */

:root {
	
	/* #f2be00 in decimal RGB */
  --main-color: #192c71;
  
  /* #0c2d62 in decimal RGB */
  --main-color-two: #F3012A;

  --main-color-three: #c3d1ff;
  
  /* #f2be00 in decimal RGBA */
  --main-color-two-rgba: rgb(242,190,0,0.90);
  
  /* #ffffff in decimal RGB */
  --white-color:rgb(255,255,255);
  
  /* #ffffff in decimal RGBA */
  --white-color-opicity:rgba(255,255,255,0.80);
  
  /* #ffffff in decimal RGBA */
  --white-color-opicity-two:rgba(255,255,255,0.20);
  
  /* #000000 in decimal RGB */
  --black-color:rgb(0,0,0);
  
  /* #ffffff in decimal RGBA Two */
  --black-color-opicity:rgba(0,0,0,0.80);
  
  /* #555555 in decimal RGB */
  --text-color:rgb(85,85,85);
  
  /* #00162b Heading Color in decimal RGB */
  --heading-color:rgb(0,22,43);
  
  /* #f4f4f4 Grey Color in decimal RGB */
  --heading-color:rgb(244,244,244);
  

  --font-family-inter: "Poppins", sans-serif;
  --font-family-nunito: "Poppins", sans-serif;

  
	
/* Heading Fonts */
  
  --font-12: 12px;
  --font-13: 13px;
  --font-14: 14px;
  --font-15: 15px;
  --font-16: 16px;
  --font-18: 18px;
  --font-20: 20px;
  --font-22: 22px;
  --font-24: 24px;
  --font-26: 26px;
  --font-28: 28px;
  --font-30: 30px;
  --font-34: 34px;
  --font-36: 36px;
  --font-40: 40px;
  --font-44: 44px;
  --font-48: 48px;
  --font-50: 50px;
  --font-52: 52px;
  --font-54: 54px;
  --font-56: 56px;
  --font-58: 58px;
  --font-60: 60px;
  --font-62: 62px;
  --font-64: 64px;
  --font-66: 66px;
  --font-68: 68px;
  --font-70: 70px;
  --font-72: 72px;
  --font-74: 74px;
  --font-76: 76px;
  --font-78: 78px;
  --font-80: 80px;
  --font-90: 90px;
  --font-100: 100px;
  --font-110: 110px;
  --font-120: 120px;
  --font-150: 150px;
  --font-200: 200px;
  
  --margin-zero: 0px;
  
  /* Margin Left Variables */
  --margin-left-5: 5px;
  --margin-left-10: 10px;
  --margin-left-15: 15px;
  --margin-left-20: 20px;
  --margin-left-25: 25px;
  --margin-left-30: 30px;
  --margin-left-35: 35px;
  --margin-left-40: 40px;
  --margin-left-45: 45px;
  --margin-left-50: 50px;
  --margin-left-55: 55px;
  --margin-left-60: 60px;
  --margin-left-65: 65px;
  --margin-left-70: 70px;
  --margin-left-75: 75px;
  --margin-left-80: 80px;
  --margin-left-85: 85px;
  --margin-left-90: 90px;
  --margin-left-95: 95px;
  --margin-left-100: 100px;
  
  /* Margin Top Variables */
  --margin-top-5: 5px;
  --margin-top-10: 10px;
  --margin-top-15: 15px;
  --margin-top-20: 20px;
  --margin-top-25: 25px;
  --margin-top-30: 30px;
  --margin-top-35: 35px;
  --margin-top-40: 40px;
  --margin-top-45: 45px;
  --margin-top-50: 50px;
  --margin-top-55: 55px;
  --margin-top-60: 60px;
  --margin-top-65: 65px;
  --margin-top-70: 70px;
  --margin-top-75: 75px;
  --margin-top-80: 80px;
  --margin-top-85: 85px;
  --margin-top-90: 90px;
  --margin-top-95: 95px;
  --margin-top-100: 100px;
  --margin-top-110: 110px;
  
  /* Margin Right Variables */
  --margin-right-5: 5px;
  --margin-right-10: 10px;
  --margin-right-15: 15px;
  --margin-right-20: 20px;
  --margin-right-25: 25px;
  --margin-right-30: 30px;
  --margin-right-35: 35px;
  --margin-right-40: 40px;
  --margin-right-45: 45px;
  --margin-right-50: 50px;
  --margin-right-55: 55px;
  --margin-right-60: 60px;
  --margin-right-65: 65px;
  --margin-right-70: 70px;
  --margin-right-75: 75px;
  --margin-right-80: 80px;
  --margin-right-85: 85px;
  --margin-right-90: 90px;
  --margin-right-95: 95px;
  --margin-right-100: 100px;
  
  /* Margin Bottom Variables */
  --margin-bottom-5: 5px;
  --margin-bottom-10: 10px;
  --margin-bottom-15: 15px;
  --margin-bottom-20: 20px;
  --margin-bottom-25: 25px;
  --margin-bottom-30: 30px;
  --margin-bottom-35: 35px;
  --margin-bottom-40: 40px;
  --margin-bottom-45: 45px;
  --margin-bottom-50: 50px;
  --margin-bottom-55: 55px;
  --margin-bottom-60: 60px;
  --margin-bottom-65: 65px;
  --margin-bottom-70: 70px;
  --margin-bottom-75: 75px;
  --margin-bottom-80: 80px;
  --margin-bottom-85: 85px;
  --margin-bottom-90: 90px;
  --margin-bottom-95: 95px;
  --margin-bottom-100: 100px;
  --margin-bottom-150: 150px;
  --margin-bottom-200: 200px;
  
  
  --padding-zero: 0px;
  
  /* Padding Left Variables */
  --padding-left-5: 5px;
  --padding-left-10: 10px;
  --padding-left-15: 15px;
  --padding-left-20: 20px;
  --padding-left-25: 25px;
  --padding-left-30: 30px;
  --padding-left-35: 35px;
  --padding-left-40: 40px;
  --padding-left-45: 45px;
  --padding-left-50: 50px;
  --padding-left-55: 55px;
  --padding-left-60: 60px;
  --padding-left-65: 65px;
  --padding-left-70: 70px;
  --padding-left-75: 75px;
  --padding-left-80: 80px;
  --padding-left-85: 85px;
  --padding-left-90: 90px;
  --padding-left-95: 95px;
  --padding-left-100: 100px;
  --padding-left-100: 110px;
  --padding-left-120: 120px;
  --padding-left-130: 130px;
  --padding-left-140: 140px;
  --padding-left-150: 150px;
  --padding-left-160: 160px;
  --padding-left-170: 170px;
  --padding-left-180: 180px;
  --padding-left-190: 190px;
  --padding-left-200: 200px;
  --padding-left-220: 220px;
  --padding-left-230: 230px;
  
  /* Padding Top Variables */
  --padding-top-5: 5px;
  --padding-top-10: 10px;
  --padding-top-15: 15px;
  --padding-top-20: 20px;
  --padding-top-25: 25px;
  --padding-top-30: 30px;
  --padding-top-35: 35px;
  --padding-top-40: 40px;
  --padding-top-45: 45px;
  --padding-top-50: 50px;
  --padding-top-55: 55px;
  --padding-top-60: 60px;
  --padding-top-65: 65px;
  --padding-top-70: 70px;
  --padding-top-75: 75px;
  --padding-top-80: 80px;
  --padding-top-85: 85px;
  --padding-top-90: 90px;
  --padding-top-95: 95px;
  --padding-top-100: 100px;
  --padding-top-110: 110px;
  --padding-top-120: 120px;
  --padding-top-130: 130px;
  --padding-top-140: 140px;
  --padding-top-150: 150px;
  --padding-top-200: 200px;
  
  /* Padding Right Variables */
  --padding-right-5: 5px;
  --padding-right-10: 10px;
  --padding-right-15: 15px;
  --padding-right-20: 20px;
  --padding-right-25: 25px;
  --padding-right-30: 30px;
  --padding-right-35: 35px;
  --padding-right-40: 40px;
  --padding-right-45: 45px;
  --padding-right-50: 50px;
  --padding-right-55: 55px;
  --padding-right-60: 60px;
  --padding-right-65: 65px;
  --padding-right-70: 70px;
  --padding-right-75: 75px;
  --padding-right-80: 80px;
  --padding-right-85: 85px;
  --padding-right-90: 90px;
  --padding-right-95: 95px;
  --padding-right-100: 100px;
  --padding-right-110: 110px;
  --padding-right-150: 150px;
  --padding-right-160: 160px;
  --padding-right-170: 170px;
  --padding-right-200: 200px;
  --padding-right-250: 250px;
  
  /* Padding Bottom Variables */
  --padding-bottom-5: 5px;
  --padding-bottom-10: 10px;
  --padding-bottom-15: 15px;
  --padding-bottom-20: 20px;
  --padding-bottom-25: 25px;
  --padding-bottom-30: 30px;
  --padding-bottom-35: 35px;
  --padding-bottom-40: 40px;
  --padding-bottom-45: 45px;
  --padding-bottom-50: 50px;
  --padding-bottom-55: 55px;
  --padding-bottom-60: 60px;
  --padding-bottom-65: 65px;
  --padding-bottom-70: 70px;
  --padding-bottom-75: 75px;
  --padding-bottom-80: 80px;
  --padding-bottom-85: 85px;
  --padding-bottom-90: 90px;
  --padding-bottom-95: 95px;
  --padding-bottom-100: 100px;
  --padding-bottom-150: 150px;
  --padding-bottom-200: 200px;
  --padding-bottom-250: 250px;
  
}

/*** 

====================================================================
	Reset
====================================================================

 ***/
 
* {
	margin:0px;
	padding:0px;
	border:none;
	outline:none;
}

/*** 

====================================================================
	Global Settings
====================================================================

 ***/

body {
	font-family: "Poppins", sans-serif;
	font-size:14px;
	color:var(--text-color);
	line-height:1.6em;
	font-weight:400;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
}

.bordered-layout .page-wrapper{
	padding:0px 50px 0px;
}

a{
	text-decoration:none;
	cursor:pointer;
	color:var(--main-color);
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

button,
a:hover,a:focus,a:visited{
	text-decoration:none;
	outline:none !important;
}

h1,h2,h3,h4,h5,h6 {
	position:relative;
	margin:0px;
	background:none;
	color:var(--main-color-two);
	font-family:var(--font-family-inter);
}

input,button,select,textarea{
	
}

textarea{
	overflow:hidden;
}

.text,
p{
	position:relative;
	line-height:1.7em;
	font-size:17px;
	color:#000;
	font-family: "Raleway", sans-serif;
		font-weight:500;
}

/* Typography */

h1{
	line-height:1.2em;
	font-weight:700;
	font-size:var(--font-54);
}

h2{
	line-height:1.2em;
	font-weight:700;
	font-size:var(--font-40);
}

h3{
	line-height:1.3em;
	font-weight:700;
	font-size:var(--font-30);
}

h4{
	line-height:1.4em;
	font-weight:700;
	font-size:var(--font-24);
}

h5{
	line-height:1.4em;
	font-weight:700;
	font-size:var(--font-20);
}

h6{
	line-height:1.6em;
	font-weight:700;
	font-size:var(--font-18);
}

.auto-container{
	position:static;
	max-width:1200px;
	padding:0px 15px;
	margin:0 auto;
}

.medium-container{
	position:static;
	max-width:1000px;
	padding:0px 15px;
	margin:0 auto;
}

.page-wrapper{
	position:relative;
	margin:0 auto;
	width:100%;
	overflow:hidden;
	min-width:300px;
}

ul,li{
	list-style:none;
	padding:0px;
	margin:0px;	
}

img{
	display:inline-block;
	max-width:100%;
}

.theme-btn{
	cursor:pointer;
	display:inline-block;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.centered{
	text-align:center;	
}

::-webkit-input-placeholder{color: inherit;}
::-moz-input-placeholder{color: inherit;}
::-ms-input-placeholder{color: inherit;}

/***

====================================================================
	Scroll To Top style
====================================================================

***/

.scroll-to-top{
	position:fixed;
	bottom:15px;
	right:15px;
	width:40px;
	height:40px;
	color:var(--main-color);
	font-size:var(--font-16);
	text-transform:uppercase;
	line-height:38px;
	text-align:center;
	z-index:100;
	cursor:pointer;
	background:var(--white-color);
	display:none;
	box-shadow:0px 0px 10px rgba(0,0,0,0.15);
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.scroll-to-top:hover{
	color:var(--white-color);
	background:var(--main-color);
}

/* List Style One */

.list-style-one{
	position:relative;
}

.list-style-one li{
	position:relative;
	color:var(--white-color);
	font-size:var(--font-16);
	font-weight:400;
	line-height:1.8em;
	font-family:var(--font-family-fira);
	margin-bottom:var(--margin-bottom-10);
	padding-left:var(--padding-left-30);
}

.list-style-one li a{
	position:relative;
	color:var(--white-color);
	font-family:var(--font-family-fira);
}

.list-style-one li a:hover{
	color:var(--main-color);
}

.list-style-one li .icon{
	position:absolute;
	left:0px;
	top:5px;
	color:var(--main-color);
	font-size:var(--font-18);
	line-height:1em;
	font-weight:300;
	-webkit-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	transition:all 300ms ease;
}

/* List Style Two */

.list-style-two{
	position:relative;
}

.list-style-two li{
	position:relative;
	color:var(--main-color-two);
	font-size:var(--font-18);
	padding-left:var(--padding-left-35);
	font-weight:600;
	line-height:1.8em;
	font-family:var(--font-family-fira);
	margin-bottom:var(--margin-bottom-5);
}

.list-style-two li:before{
	position:absolute;
	content: "\f1c5";
	left:0px;
	top:0px;
	font-weight:400;
	font-family: "flaticon";
	color:var(--main-color);
}

.list-style-two li:last-child{
	margin-bottom:var(--margin-zero);
}

/* Btn Style One */

.btn-style-one{
	position: relative;
	display: inline-grid;
	font-size: var(--font-16);
	line-height: 30px;
	color: var(--white-color);
	font-weight: 600;
	overflow: hidden;
	background:none;
	text-align:center;
	border-radius:4px;
	padding: 14px 42px 14px;
	text-transform: capitalize;
	background-color:var(--main-color);
	font-family:var(--font-family-inter);
}

.btn-style-one:before{
	position: absolute;
	content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transform: scale(0.2, 1);
    transform: scale(0.2, 1);
	background-color:var(--white-color);
}

.btn-style-one .txt{
	position:relative;
	z-index:1;
}

.btn-style-one:hover::before{
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.btn-style-one:hover{
	color:var(--main-color-two);
}

/* Btn Style Two */

.btn-style-two{
	position: relative;
	display: inline-grid;
	font-size: var(--font-16);
	line-height: 30px;
	color: var(--main-color-two);
	font-weight: 600;
	overflow: hidden;
	background:none;
	text-align:center;
	border-radius:4px;
	padding: 14px 42px 14px;
	text-transform: capitalize;
	background-color:var(--main-color);
	font-family:var(--font-family-inter);
}

.btn-style-two:before{
	position: absolute;
	content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transform: scale(0.2, 1);
    transform: scale(0.2, 1);
	background-color: var(--main-color-two);
}

.btn-style-two .txt{
	position:relative;
	z-index:1;
}

.btn-style-two:hover::before{
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.btn-style-two:hover{
	color:var(--white-color);
}

/* Btn Style Three */

.btn-style-three{
	position: relative;
	display: inline-grid;
	font-size: var(--font-16);
	line-height: 30px;
	color: var(--white-color);
	font-weight: 500;
	overflow: hidden;
	background:none;
	text-align:center;
	border-radius:4px;
	padding: 14px 22px 14px;
	text-transform: uppercase;
	background-color:#F3012A;
	font-family: "Raleway", sans-serif;
	margin-top:17px;
}

.btn-style-three:before{
	position: absolute;
	content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transform: scale(0.2, 1);
    transform: scale(0.2, 1);
	background-color: var(--main-color);
}

.btn-style-three .txt{
	position:relative;
	z-index:1;
}

.btn-style-three:hover::before{
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.btn-style-three:hover{
	color:var(--white-color);
}

/* Btn Style Four */

.btn-style-four{
	position: relative;
	display: inline-grid;
	font-size: var(--font-16);
	line-height: 30px;
	color: var(--white-color);
	font-weight: 600;
	overflow: hidden;
	background:none;
	text-align:center;
	border-radius:4px;
	padding: 14px 42px 14px;
	text-transform: uppercase;
	background-color:var(--main-color-two);
	font-family:var(--font-family-inter);
}

.btn-style-four:before{
	position: absolute;
	content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transform: scale(0.2, 1);
    transform: scale(0.2, 1);
	background-color: var(--white-color);
}

.btn-style-four .txt{
	position:relative;
	z-index:1;
}

.btn-style-four:hover::before{
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.btn-style-four:hover{
	color:var(--main-color-two);
}

/* Btn Style Five */

.btn-style-five{
	position: relative;
	display: inline-grid;
	font-size: var(--font-16);
	line-height: 30px;
	color: var(--main-color-two);
	font-weight: 600;
	overflow: hidden;
	background:none;
	text-align:center;
	border-radius:0px;
	padding: 14px 42px 14px;
	text-transform: capitalize;
	background-color:var(--main-color);
	font-family:var(--font-family-inter);
}

.btn-style-five:before{
	position: absolute;
	content: "";
    right: 0;
    bottom: 0;
    width: 10px;
    height: 10px;
    z-index: 1;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
	background-color:var(--main-color-two);
}

.btn-style-five:hover::before{
	width:100%;
	height:100%;
}

.btn-style-five .txt{
	position:relative;
	z-index:1;
}

.btn-style-five:hover::before{
    opacity: 1;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.btn-style-five:hover{
	color:var(--white-color);
}

/* Btn Style Six */

.btn-style-six{
	position: relative;
	display: inline-grid;
	font-size: var(--font-16);
	line-height: 30px;
	color: var(--white-color);
	font-weight: 600;
	overflow: hidden;
	background:none;
	text-align:center;
	border-radius:0px;
	padding: 14px 42px 14px;
	text-transform: capitalize;
	background-color:var(--main-color-two);
	font-family:var(--font-family-inter);
}

.btn-style-six:before{
	position: absolute;
	content: "";
    right: 0;
    bottom: 0;
    width: 10px;
    height: 10px;
    z-index: 1;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
	background-color:var(--main-color);
}

.btn-style-six:hover::before{
	background-color:var(--white-color);
}

.btn-style-six .txt{
	position:relative;
	z-index:1;
}

.btn-style-six:hover{
	color:var(--white-color);
}

.theme_color{
	color:#0060ff;
}



img{
	display:inline-block;
	max-width:100%;
	height:auto;	
}

/*** 

====================================================================
	Section Title
====================================================================

***/

.sec-title{
	position:relative;
	margin-bottom:var(--margin-bottom-40);
}

.sec-title .title{
	position:relative;
	color:#19697c;
	font-size:18px;
	margin-bottom:var(--margin-bottom-15);
}

.sec-title h2{
	font-weight:900;
	color:var(--main-color-two); 
	text-transform:uppercase;
	margin-bottom:var(--margin-bottom-10);
}

.sec-title h2 span{
	font-weight:300;
	color:var(--main-color);
}

.sec-title.style-two h2{
	text-transform:capitalize;
}

.sec-title .icon{
	position:absolute;
	left:10px;
	top:-12px;
	opacity:0.20;
	line-height:1em;
	font-size:var(--font-70);
	color:var(--main-color-two);
}

.sec-title .text{
	margin-top:var(--margin-top-25);
}

.sec-title.light .text,
.sec-title.light h2{
	color:var(--white-color);
}

.sec-title.centered{
	text-align: center !important;
}

.sec-title.centered .separator{
	margin:0 auto;
}

.sec-title.centered .icon{
	left:50% !important;
	transform:translateX(-50%);
}