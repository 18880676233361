/*** 

====================================================================
    Footer Section
====================================================================

 ***/

 .main-footer {
    position: relative;
    background-color: var(--main-color);
}

.main-footer .widgets-section {
    position: relative;
    padding: 80px 0px 30px;
}

.main-footer .footer-column {
    margin-bottom: 30px;
}

.main-footer .footer-column .logo-widget .footer-logo {
    position: relative;
}

.main-footer .logo-widget .text {
    position: relative;
    color: #bcc1c7;
    font-size: 17px;
    line-height: 1.6em;
    margin-top: 35px;
    margin-bottom: 20px;
}

.main-footer .footer-column h2 {
    font-size: 20px;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 30px;
    padding-bottom: 20px;
    text-transform: capitalize;
	font-family: 'Poppins', sans-serif;

}

.main-footer .footer-column h2:before {
    position: absolute;
    content: '';
    left: 0px;
    bottom: 0px;
    width: 50px;
    height: 3px;
    border-radius: 5px;
    background-color: var(--main-color-two);
}

.main-footer a{color: var(--white-color)}
.main-footer a:hover{color: var(--main-color-two)}
.areas-list{column-count:2}
.footer-logo img{height: 65px;}
/*Contact Info*/

.main-footer .contact-info {
    position: relative;
}

.main-footer .contact-info li {
    position: relative;
    line-height: 1.6em;
    padding-left: 50px;
    margin-bottom: 16px;
    color: #bcc1c7;
    font-size: 17px;
}

.main-footer .contact-info li:last-child {
    margin-bottom: 0px;
}

.main-footer .contact-info li .title {
    color: #ffffff;
    display: block;
    font-size: 18px;
    font-weight: 500;
}

.main-footer .contact-info li .icon {
    position: absolute;
    left: 0px;
    top: 5px;
    line-height: 1em;
    font-size: 30px;
    color: var(--main-color-two);
}

.main-footer .services-list {
    position: relative;
    margin-bottom: 30px;
}

.main-footer .services-list li {
    position: relative;
    margin-bottom: 13px;
}

.main-footer .services-list li a {
    color: #fff;
    font-size: 17px;
    font-weight: 500;
    padding-left: 20px;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
	font-family: 'Raleway', sans-serif;
}

.main-footer .services-list li a:before {
    position: absolute;
    content: '\f104';
    left: 0px;
    top: 0px;
    font-size: 14px;
    font-family: 'FontAwesome';
}

.main-footer .services-list li a:hover {
    color: var(--main-color-two);
}

.main-footer .footer-column .links-widget .date {
    position: relative;
    color: #bcc1c7;
    font-size: 17px;
}


/*Tweet Widget*/

.main-footer .news-widget .news-post {
    color: #c9c9c9;
    line-height: 20px;
    margin-bottom: 35px;
    padding: 0 0 0 100px;
    position: relative;
}

.main-footer .news-widget .news-post .news-content {
    margin-bottom: 9px;
}

.main-footer .news-widget .news-post a {
    color: #c9c9c9;
    position: relative;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    line-height: 27px;
}

.main-footer .news-widget .news-post .time {
    color: var(--main-color-two);
    font-size: 12px;
    font-style: italic;
    position: relative;
}

.main-footer .news-widget .news-post .image-thumb {
    height: 80px;
    left: 0;
    position: absolute;
    top: 5px;
    width: 80px;
}

.main-footer .news-widget .news-post .image-thumb img {
    display: block;
    width: 80px;
}


/*Contact Widget*/

.main-footer .contact-widget .footer-form .form-group {
    position: relative;
    display: block;
    margin-bottom: 20px;
}

.main-footer .footer-form .form-group input[type="text"],
.main-footer .footer-form .form-group input[type="tel"],
.main-footer .footer-form .form-group input[type="email"],
.main-footer .footer-form .form-group textarea {
    position: relative;
    display: block;
    width: 100%;
    line-height: 28px;
    padding: 10px 20px;
    height: 50px;
    font-size: 13px;
    color: #ffffff;
    border-radius: 2px;
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 0.10);
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

.main-footer .contact-widget .footer-form .form-group textarea {
    height: 110px;
    resize: none;
}

.main-footer .contact-widget .footer-form .form-group input[type="text"]:focus,
.main-footer .contact-widget .footer-form .form-group input[type="tel"]:focus,
.main-footer .contact-widget .footer-form .form-group input[type="email"]:focus,
.main-footer .contact-widget .footer-form .form-group textarea:focus {
    border-color: #fdc236;
}

.main-footer .contact-widget .footer-form .form-group input::-webkit-input-placeholder,
.main-footer .contact-widget .footer-form .form-group textarea::-webkit-input-placeholder {
    color: #a8a8a8;
}

.main-footer .contact-widget .footer-form .form-group input[type="submit"],
.main-footer .contact-widget .footer-form button {
    padding: 11px 45px;
    width: 100%;
}

.main-footer .footer-bottom {
    position: relative;
    padding: 30px 0px 30px;
    background-color: #1e2126;
    border-top: 1px solid rgba(255, 255, 255, 0.14);
}

.main-footer .footer-bottom .copyright {
    position: relative;
    color: #bcc1c7;
	font-family: 'Raleway', sans-serif;
	font-size:17px;
}

.main-footer .footer-bottom .nav-column {
    position: relative;
    text-align: right;
}

.main-footer .footer-bottom .nav-column .footer-nav {
    position: relative;
}

.main-footer .footer-bottom .nav-column .footer-nav {
    position: relative;
}

.main-footer .footer-bottom .nav-column .footer-nav li {
    position: relative;
    padding-right: 15px;
    margin-right: 15px;
    display: inline-block;
    line-height: 1em;
    border-right: 1px solid #9799a9;
}

.main-footer .footer-bottom .nav-column .footer-nav li a {
    position: relative;
    color: #bcc1c7;
    font-size: 15px;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;

}

.main-footer .footer-bottom .nav-column .footer-nav li:last-child {
    margin-right: 0px;
    padding-right: 0px;
    border: 0px;
}

.main-footer .footer-bottom .nav-column .footer-nav li a:hover {
    color: #ffffff;
}


/*Footer Style Two*/

.footer-style-two {
    position: relative;
    background-color: #121212;
}

.footer-style-two .footer-widget {
    position: relative;
    margin-bottom: 40px;
}

.footer-style-two .widgets-section {
    position: relative;
    padding: 85px 0px 20px;
    background-color: #121212;
}

.footer-style-two .footer-widget h2 {
    font-size: 24px;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 30px;
    padding-bottom: 15px;
    text-transform: capitalize;
}

.footer-style-two .footer-widget h2:before {
    position: absolute;
    content: '';
    left: 0px;
    bottom: 0px;
    width: 50px;
    height: 3px;
    border-radius: 5px;
    background-color: #fdc236;
}

.footer-style-two .about-widget .text {
    position: relative;
    font-size: 16px;
    color: #9799a9;
    margin-bottom: 20px;
}


/*posts widget*/

.footer-style-two .posts-widget .post {
    position: relative;
    min-height: 95px;
    padding-left: 90px;
    margin-bottom: 25px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.footer-style-two .posts-widget .post:last-child {
    margin-bottom: 0px;
    border: 0px;
}

.footer-style-two .posts-widget .post .post-thumb {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 70px;
    height: 70px;
}

.footer-style-two .posts-widget .post .post-thumb img {
    display: block;
    width: 100%;
    border-radius: 5px;
}

.footer-style-two .posts-widget .post .desc-text {
    position: relative;
    font-size: 15px;
    line-height: 1.6em;
    padding: 0px 0px 0px;
    top: -4px;
    font-weight: 400;
}

.footer-style-two .posts-widget .post .desc-text a {
    color: #9799a9;
}

.footer-style-two .posts-widget .post .time {
    position: relative;
    font-size: 14px;
    line-height: 24px;
    color: #fdc236;
}


/*Hours Widget*/

.footer-style-two .hours-widget ul {
    position: relative;
}

.footer-style-two .hours-widget ul li {
    position: relative;
    color: #9799a9;
    font-size: 17px;
    margin-bottom: 4px;
}


/*Gallery Widget*/

.footer-style-two .gallery-widget .images-outer {
    position: relative;
    margin: 0px -5px;
}

.footer-style-two .gallery-widget .image-box {
    position: relative;
    float: left;
    width: 33.333%;
    padding: 0px 6px;
    margin-bottom: 10px;
}

.footer-style-two .gallery-widget .image-box img {
    position: relative;
    display: block;
    width: 100%;
    border-radius: 5px;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
}

.footer-style-two .gallery-widget .image-box img:hover {
    opacity: 0.5;
}

.footer-style-two .footer-bottom {
    position: relative;
    padding: 15px 0px;
    text-align: center;
    margin-top: 30px;
    background-color: #fdc236;
}

.footer-style-two .footer-bottom .copyright {
    color: #ffffff;
    font-size: 16px;
    font-weight: 300;
}


.social-box.footer{
    display: flex;
    gap: 20px;
    margin-top: 35px;
}
.social-box.footer li a{
    font-size: 25px;
}