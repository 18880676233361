@font-face {
  font-family: "Flaticon";
  src: url("../fonts/flaticon.eot");
  src: url("../fonts/flaticond41d.eot?#iefix") format("embedded-opentype"),
       url("../fonts/flaticon.woff") format("woff"),
       url("../fonts/flaticon.ttf") format("truetype"),
       url("../fonts/flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
 	font-family: Flaticon;
	font-style: normal;
}

.flaticon-play-button:before {
    content: "\f101";
}
.flaticon-straight-quotes:before {
    content: "\f102";
}
.flaticon-blocks-with-angled-cuts:before {
    content: "\f103";
}
.flaticon-home-page:before {
    content: "\f104";
}
.flaticon-magnifying-glass:before {
    content: "\f105";
}
.flaticon-angle-arrow-pointing-to-right:before {
    content: "\f106";
}
.flaticon-media-play-symbol:before {
    content: "\f107";
}
.flaticon-quotation-mark:before {
    content: "\f108";
}
.flaticon-right-quotes-symbol:before {
    content: "\f109";
}
.flaticon-left-quotes-sign:before {
    content: "\f10a";
}
.flaticon-graduate-cap:before {
    content: "\f10b";
}
.flaticon-world:before {
    content: "\f10c";
}
.flaticon-play-arrow:before {
    content: "\f10d";
}
.flaticon-chat-bubbles-with-ellipsis:before {
    content: "\f10e";
}
.flaticon-arrow-pointing-to-right:before {
    content: "\f10f";
}
.flaticon-telephone:before {
    content: "\f110";
}
.flaticon-big-anchor:before {
    content: "\f111";
}
.flaticon-security:before {
    content: "\f112";
}
.flaticon-shopping-bag:before {
    content: "\f113";
}
.flaticon-plus-symbol:before {
    content: "\f114";
}
.flaticon-right-arrow:before {
    content: "\f115";
}
.flaticon-left-arrow:before {
    content: "\f116";
}
.flaticon-email:before {
    content: "\f117";
}
.flaticon-magnifying-glass-1:before {
    content: "\f118";
}
.flaticon-user:before {
    content: "\f119";
}
.flaticon-money-bag:before {
    content: "\f11a";
}
.flaticon-dollar-symbol:before {
    content: "\f11b";
}
.flaticon-garbage:before {
    content: "\f11c";
}
.flaticon-next:before {
    content: "\f11d";
}
.flaticon-placeholder:before {
    content: "\f11e";
}
.flaticon-heart:before {
    content: "\f11f";
}
.flaticon-settings:before {
    content: "\f120";
}
.flaticon-like:before {
    content: "\f121";
}
.flaticon-upload:before {
    content: "\f122";
}
.flaticon-avatar:before {
    content: "\f123";
}
.flaticon-share:before {
    content: "\f124";
}
.flaticon-house:before {
    content: "\f125";
}
.flaticon-cancel:before {
    content: "\f126";
}
.flaticon-headphones:before {
    content: "\f127";
}
.flaticon-shopping-cart:before {
    content: "\f128";
}
.flaticon-envelope:before {
    content: "\f129";
}
.flaticon-menu:before {
    content: "\f12a";
}
.flaticon-menu-1:before {
    content: "\f12b";
}
.flaticon-multiply:before {
    content: "\f12c";
}
.flaticon-search:before {
    content: "\f12d";
}
.flaticon-unlink:before {
    content: "\f12e";
}
.flaticon-left-quote:before {
    content: "\f12f";
}
.flaticon-right-quote:before {
    content: "\f130";
}
.flaticon-wifi:before {
    content: "\f131";
}
.flaticon-paper-plane:before {
    content: "\f132";
}
.flaticon-pdf:before {
    content: "\f133";
}
.flaticon-edit:before {
    content: "\f134";
}
.flaticon-eye:before {
    content: "\f135";
}
.flaticon-placeholder-1:before {
    content: "\f136";
}
.flaticon-chat:before {
    content: "\f137";
}
.flaticon-home:before {
    content: "\f138";
}
.flaticon-megaphone:before {
    content: "\f139";
}
.flaticon-padlock:before {
    content: "\f13a";
}
.flaticon-message:before {
    content: "\f13b";
}
.flaticon-skyline:before {
    content: "\f13c";
}
.flaticon-down-arrow:before {
    content: "\f13d";
}
.flaticon-next-1:before {
    content: "\f13e";
}
.flaticon-mail:before {
    content: "\f13f";
}
.flaticon-secure-shield:before {
    content: "\f140";
}
.flaticon-trophy:before {
    content: "\f141";
}
.flaticon-tick:before {
    content: "\f142";
}
.flaticon-maps-and-flags:before {
    content: "\f143";
}
.flaticon-tick-1:before {
    content: "\f144";
}
.flaticon-user-1:before {
    content: "\f145";
}
.flaticon-bar-chart:before {
    content: "\f146";
}
.flaticon-right:before {
    content: "\f147";
}
.flaticon-left:before {
    content: "\f148";
}
.flaticon-call:before {
    content: "\f149";
}
.flaticon-play-button-1:before {
    content: "\f14a";
}
.flaticon-search-1:before {
    content: "\f14b";
}
.flaticon-call-1:before {
    content: "\f14c";
}
.flaticon-hand-shake:before {
    content: "\f14d";
}
.flaticon-map:before {
    content: "\f14e";
}
.flaticon-crane:before {
    content: "\f14f";
}
.flaticon-winch:before {
    content: "\f150";
}
.flaticon-briefcase:before {
    content: "\f151";
}
.flaticon-briefcase-1:before {
    content: "\f152";
}
.flaticon-eye-1:before {
    content: "\f153";
}
.flaticon-like-1:before {
    content: "\f154";
}
.flaticon-location:before {
    content: "\f155";
}
.flaticon-down-arrow-1:before {
    content: "\f156";
}
.flaticon-up-arrow:before {
    content: "\f157";
}
.flaticon-left-arrow-1:before {
    content: "\f158";
}
.flaticon-right-arrow-1:before {
    content: "\f159";
}
.flaticon-list:before {
    content: "\f15a";
}
.flaticon-email-1:before {
    content: "\f15b";
}
.flaticon-next-2:before {
    content: "\f15c";
}
.flaticon-medal:before {
    content: "\f15d";
}
.flaticon-email-2:before {
    content: "\f15e";
}
.flaticon-quote:before {
    content: "\f15f";
}
.flaticon-menu-2:before {
    content: "\f160";
}
.flaticon-email-3:before {
    content: "\f161";
}
.flaticon-clock:before {
    content: "\f162";
}
.flaticon-full-screen:before {
    content: "\f163";
}
.flaticon-reply-all:before {
    content: "\f164";
}
.flaticon-megaphone-1:before {
    content: "\f165";
}
.flaticon-house-1:before {
    content: "\f166";
}
.flaticon-bar-chart-1:before {
    content: "\f167";
}
.flaticon-group:before {
    content: "\f168";
}
.flaticon-map-1:before {
    content: "\f169";
}
.flaticon-car:before {
    content: "\f16a";
}
.flaticon-like-2:before {
    content: "\f16b";
}
.flaticon-iphone:before {
    content: "\f16c";
}
.flaticon-link:before {
    content: "\f16d";
}
.flaticon-user-2:before {
    content: "\f16e";
}
.flaticon-sketch:before {
    content: "\f16f";
}
.flaticon-placeholder-2:before {
    content: "\f170";
}
.flaticon-graduation-cap:before {
    content: "\f171";
}
.flaticon-checked:before {
    content: "\f172";
}
.flaticon-wall-clock:before {
    content: "\f173";
}
.flaticon-line-chart:before {
    content: "\f174";
}
.flaticon-checked-1:before {
    content: "\f175";
}
.flaticon-quote-1:before {
    content: "\f176";
}
.flaticon-play:before {
    content: "\f177";
}
.flaticon-user-3:before {
    content: "\f178";
}
.flaticon-phone-call:before {
    content: "\f179";
}
.flaticon-cancel-1:before {
    content: "\f17a";
}
.flaticon-coffee-cup:before {
    content: "\f17b";
}
.flaticon-law:before {
    content: "\f17c";
}
.flaticon-quotation:before {
    content: "\f17d";
}
.flaticon-plus:before {
    content: "\f17e";
}
.flaticon-trophy-1:before {
    content: "\f17f";
}
.flaticon-heart-1:before {
    content: "\f180";
}
.flaticon-calendar:before {
    content: "\f181";
}
.flaticon-clipboard:before {
    content: "\f182";
}
.flaticon-email-4:before {
    content: "\f183";
}
.flaticon-support:before {
    content: "\f184";
}
.flaticon-car-1:before {
    content: "\f185";
}
.flaticon-map-2:before {
    content: "\f186";
}
.flaticon-left-arrow-2:before {
    content: "\f187";
}
.flaticon-check:before {
    content: "\f188";
}
.flaticon-down-arrow-2:before {
    content: "\f189";
}
.flaticon-time:before {
    content: "\f18a";
}
.flaticon-next-3:before {
    content: "\f18b";
}
.flaticon-shopping-bag-1:before {
    content: "\f18c";
}
.flaticon-loupe:before {
    content: "\f18d";
}
.flaticon-home-1:before {
    content: "\f18e";
}
.flaticon-briefcase-2:before {
    content: "\f18f";
}
.flaticon-globe:before {
    content: "\f190";
}
.flaticon-stats:before {
    content: "\f191";
}
.flaticon-building:before {
    content: "\f192";
}
.flaticon-planet-earth:before {
    content: "\f193";
}
.flaticon-pin:before {
    content: "\f194";
}
.flaticon-solidarity:before {
    content: "\f195";
}
.flaticon-maintenance:before {
    content: "\f196";
}
.flaticon-conversation:before {
    content: "\f197";
}
.flaticon-helmet:before {
    content: "\f198";
}
.flaticon-checked-2:before {
    content: "\f199";
}
.flaticon-under-construction:before {
    content: "\f19a";
}
.flaticon-call-2:before {
    content: "\f19b";
}
.flaticon-portfolio:before {
    content: "\f19c";
}
.flaticon-user-4:before {
    content: "\f19d";
}
.flaticon-trophy-2:before {
    content: "\f19e";
}
.flaticon-video-camera:before {
    content: "\f19f";
}
.flaticon-shield:before {
    content: "\f1a0";
}
.flaticon-shopping-cart-1:before {
    content: "\f1a1";
}
.flaticon-timetable:before {
    content: "\f1a2";
}
.flaticon-pen:before {
    content: "\f1a3";
}
.flaticon-squares:before {
    content: "\f1a4";
}
.flaticon-search-2:before {
    content: "\f1a5";
}
.flaticon-clock-1:before {
    content: "\f1a6";
}
.flaticon-blueprint:before {
    content: "\f1a7";
}
.flaticon-comment:before {
    content: "\f1a8";
}
.flaticon-handshake:before {
    content: "\f1a9";
}
.flaticon-close:before {
    content: "\f1aa";
}
.flaticon-download:before {
    content: "\f1ab";
}
.flaticon-menu-3:before {
    content: "\f1ac";
}
.flaticon-share-1:before {
    content: "\f1ad";
}
.flaticon-tools:before {
    content: "\f1ae";
}
.flaticon-tag:before {
    content: "\f1af";
}
.flaticon-user-5:before {
    content: "\f1b0";
}
.flaticon-office-building:before {
    content: "\f1b1";
}
.flaticon-quote-2:before {
    content: "\f1b2";
}
.flaticon-quote-3:before {
    content: "\f1b3";
}
.flaticon-send:before {
    content: "\f1b4";
}
.flaticon-right-arrow-2:before {
    content: "\f1b5";
}
.flaticon-down-arrow-3:before {
    content: "\f1b6";
}
.flaticon-checked-3:before {
    content: "\f1b7";
}
.flaticon-settings-1:before {
    content: "\f1b8";
}
.flaticon-video-camera-1:before {
    content: "\f1b9";
}
.flaticon-google-play:before {
    content: "\f1ba";
}
.flaticon-shopping-cart-2:before {
    content: "\f1bb";
}
.flaticon-notepad:before {
    content: "\f1bc";
}
.flaticon-clock-2:before {
    content: "\f1bd";
}
.flaticon-global:before {
    content: "\f1be";
}
.flaticon-grid:before {
    content: "\f1bf";
}
.flaticon-web-search-engine:before {
    content: "\f1c0";
}
.flaticon-helmet-1:before {
    content: "\f1c1";
}
.flaticon-shopping-basket:before {
    content: "\f1c2";
}
.flaticon-target:before {
    content: "\f1c3";
}
.flaticon-check-1:before {
    content: "\f1c4";
}
.flaticon-double-check:before {
    content: "\f1c5";
}
.flaticon-check-2:before {
    content: "\f1c6";
}
.flaticon-gear:before {
    content: "\f1c7";
}
.flaticon-minus:before {
    content: "\f1c8";
}
.flaticon-helmet-2:before {
    content: "\f1c9";
}
.flaticon-bank:before {
    content: "\f1ca";
}
.flaticon-padlock-1:before {
    content: "\f1cb";
}
.flaticon-notebook:before {
    content: "\f1cc";
}
.flaticon-megaphone-2:before {
    content: "\f1cd";
}
.flaticon-clipboard-1:before {
    content: "\f1ce";
}
.flaticon-darts:before {
    content: "\f1cf";
}
.flaticon-location-1:before {
    content: "\f1d0";
}
.flaticon-diamond:before {
    content: "\f1d1";
}
.flaticon-customer-support:before {
    content: "\f1d2";
}
.flaticon-menu-4:before {
    content: "\f1d3";
}
.flaticon-calendar-1:before {
    content: "\f1d4";
}
.flaticon-trophy-3:before {
    content: "\f1d5";
}
.flaticon-heart-2:before {
    content: "\f1d6";
}
.flaticon-rocket:before {
    content: "\f1d7";
}
.flaticon-briefcase-3:before {
    content: "\f1d8";
}
.flaticon-atom:before {
    content: "\f1d9";
}
.flaticon-brickwall:before {
    content: "\f1da";
}
.flaticon-chat-1:before {
    content: "\f1db";
}
.flaticon-calendar-2:before {
    content: "\f1dc";
}
.flaticon-graduated:before {
    content: "\f1dd";
}
.flaticon-target-1:before {
    content: "\f1de";
}
.flaticon-pdf-1:before {
    content: "\f1df";
}
.flaticon-soccer-cup:before {
    content: "\f1e0";
}
.flaticon-calendar-3:before {
    content: "\f1e1";
}
.flaticon-placeholder-3:before {
    content: "\f1e2";
}
.flaticon-measure:before {
    content: "\f1e3";
}
.flaticon-smile:before {
    content: "\f1e4";
}
.flaticon-reply:before {
    content: "\f1e5";
}
.flaticon-globe-1:before {
    content: "\f1e6";
}
.flaticon-magnifier:before {
    content: "\f1e7";
}
.flaticon-pencil-and-ruler:before {
    content: "\f1e8";
}
.flaticon-construction-worker:before {
    content: "\f1e9";
}
.flaticon-location-2:before {
    content: "\f1ea";
}
.flaticon-telephone-1:before {
    content: "\f1eb";
}
.flaticon-tourism:before {
    content: "\f1ec";
}
.flaticon-team:before {
    content: "\f1ed";
}
.flaticon-wheelbarrow:before {
    content: "\f1ee";
}
.flaticon-hamburger:before {
    content: "\f1ef";
}
.flaticon-construction:before {
    content: "\f1f0";
}